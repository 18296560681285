import './assets/styles/index.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n.ts'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'

import App from './App'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1800}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </QueryClientProvider>
)

reportWebVitals()
