import { Skeleton } from '@mui/material'
import React from 'react'
import styles from './CryptoSkeleton.module.scss'

export const CryptoSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>

        {[...new Array(2)].map((_, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.itemContent}>
              <h3 className={styles.title}>
                <Skeleton variant="text" width={138} height={22} className={styles.titleSkeleton} />
              </h3>
              <p className={styles.text}>
                <Skeleton variant="text" width={'100%'} height={22} className={styles.textSkeleton} />
              </p>
            </div>

            <button className={styles.action}>
              <Skeleton variant="circular" width={48} height={48} className={styles.iconSkeleton} />
            </button>
          </div>
        ))}
        <p className={styles.contentDescription}>
          <Skeleton
            variant="text"
            width={'100%'}
            height={44}
            className={styles.descriptionSkeleton}
          />
        </p>
      </div>
      <div className={styles.qr}>
        <Skeleton variant="rectangular" width={160} height={160} className={styles.qrSkeleton} />
      </div>
    </div>
  )
}
