import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMethodsContext } from '../../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { Button } from '../../../../../ui-lava/Button/Button'
import { InputLabel } from '../../../../../ui-lava/Input/InputLabel/InputLabel'
import 'react-phone-input-2/lib/material.css'

import styles from './CardUrlForm.module.scss'
import { useTranslation } from 'react-i18next'
import { deleteSpaces } from '../../../../../helpers/inputDeleteSpaces'

export const CardUrlForm = ({ onSubmitCard }: { onSubmitCard: any }) => {
  const { t } = useTranslation(['components', 'ui'])
  const { stepOneData } = useMethodsContext()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  })

  const handleSubmitForm = (data: any) => {
    onSubmitCard({
      email: data?.email,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {stepOneData?.use_email && (
          <div className={styles.input}>
            <Controller
              control={control}
              name="email"
              defaultValue={''}
              rules={{
                required: {
                  value: false,
                  message: t('components:card_form.Введите email для чека'),
                },
                minLength: {
                  value: 3,
                  message: t('components:card_form.Неверный email'),
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t('components:card_form.Введите корректный email адрес'),
                },
              }}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <InputLabel
                    id="filled-basic"
                    label={t('components:card_form.Email для чека')}
                    variant="filled"
                    value={value}
                    onChange={(e) => {
                      deleteSpaces(e)
                      onChange(e)
                    }}
                    customError={errors?.email?.message || ''}
                    autoFocus
                  />
                )
              }}
            />
          </div>
        )}

        <Button
          className={styles.button}
          // onClick={(e) => {
          //   e.preventDefault()
          //   onSubmitPayment()
          // }}
        >
          {t('ui:buttons.Оплатить')}
        </Button>
      </form>
    </>
  )
}
