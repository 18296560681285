import React from 'react'
import { Outlet } from 'react-router-dom'
import './InvoiceContent.scss'
import { Service } from './Service/Service'
import { useMediaQuery } from '@mui/material'

export const InvoiceContent = () => {
  const isTabletS = useMediaQuery('(max-width: 576px)')

  return (
    <div className="content__form scroll">
      <div className="content__form-wrapper">
        <Outlet />
      </div>
      {!isTabletS && <Service />}
    </div>
  )
}
