import React, { useState } from 'react'
import { ReactComponent as IconLeftArrow } from '../../../../assets/images/ui/icon-left-arrow.svg'
import { ReactComponent as IconRightArrow } from '../../../../assets/images/ui/icon-right-arrow.svg'
import { IGetInvoiceInfo } from '../../../../services/invoice/invoice.service.types'
import { getCurrencySign } from '../../../../helpers/getCurrencySign'
import { HeaderSkeleton } from './HeaderSkeleton/HeaderSkeleton'
import { ModalWindow } from '../../../../ui-lava/ModalWindow/ModalWindow'
import { OrderDetails } from '../../../OrderDetails/OrderDetails'
import { Button } from '../../../../ui-lava/Button/Button'
import styles from './Header.module.scss'
import { useTranslation } from 'react-i18next'
import { getDomainName } from '../../../../helpers/getDomainName/getDomainName'
import { useInvoice } from '../InvoiceContext/InvoiceContext'
import { useMediaQuery } from '@mui/material'

interface Props {
  data?: IGetInvoiceInfo
  isLoadingInvoice?: boolean
  isErrorInvoice?: boolean
}

export const Header = ({ data, isErrorInvoice = false, isLoadingInvoice = false }: Props) => {
  const { t } = useTranslation(['components, ui'])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { invoiceAmount } = useInvoice()

  const isTabletS = useMediaQuery('(max-width: 576px)')

  const amount = invoiceAmount?.amount ? invoiceAmount.amount.toLocaleString() : ''

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  if (isErrorInvoice) {
    return null
  }

  if (isLoadingInvoice) {
    return <HeaderSkeleton />
  }

  return (
    <div className={styles.wrapper}>
      <ModalWindow isOpened={isModalOpen} onClose={handleCloseModal}>
        <OrderDetails orderData={data} />
      </ModalWindow>

      <div className={styles.main}>
        {data?.domain && (
          <div className={styles.button}>
            <div className={styles.buttonIcon}>
              <IconLeftArrow />
            </div>
            <div className={styles.buttonText}>
              <a className={styles.textName} href={data?.domain}>
                {t('components:header.Вернуться на сайт')}{' '}
                {getDomainName(data?.domain) ?? data?.domain}
              </a>
            </div>
          </div>
        )}
        <div className={styles.amount}>
          <div className={styles.amountValue}>
            <span className={styles.amountText}>
              {amount} {getCurrencySign(invoiceAmount?.currency ?? '')}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.details}>
        {isTabletS ? (
          <div>
            <div className={styles.button} onClick={() => setIsModalOpen(true)}>
              <div className={styles.buttonText}>
                <span className={styles.textName}>{t('ui:buttons.Детали')}</span>
              </div>
              <div className={styles.buttonIcon}>
                <IconRightArrow />
              </div>
            </div>
          </div>
        ) : (
          <Button
            className={styles.btnDetails}
            appearance="outline"
            size="medium"
            onClick={handleOpenModal}
          >
            {t('ui:buttons.Детали платежа')}
          </Button>
        )}
      </div>
    </div>
  )
}
