import React from 'react'
import styles from './PciSecure.module.scss'
import { ReactComponent as IconSecure } from 'assets/images/icons/icon-secure.svg'
import { useTranslation } from 'react-i18next'

export const PciSecure = () => {
  const { t } = useTranslation(['components'])
  return (
    <div className={styles.container}>
      <IconSecure />
      <p>{t('components:pci_service.Данные защищены по стандарту PCI DSS')}</p>
    </div>
  )
}
