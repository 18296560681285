import { FilledInputProps, TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styles from './InputLabel.module.scss'

type TextFieldPropsType = TextFieldProps & {
  customError?: string
  hideErrorMessage?: boolean
  customTheme?: string
}

export const InputLabel = styled(({ customError, ...rest }: TextFieldPropsType) => (
  <div>
    <TextField
      InputProps={{ disableUnderline: true, fullWidth: true } as Partial<FilledInputProps>}
      className={styles.input}
      {...rest}
      error={!!customError}
    />

    <AnimatePresence>
      {customError !== '' && !rest.hideErrorMessage && (
        <motion.div
          initial={{
            opacity: 0.1,
            height: 0,
            marginTop: 0,
          }}
          animate={{
            opacity: 1,
            height: 'auto',
            marginTop: 4,
          }}
          exit={{
            opacity: 0.1,
            height: 0,
            marginTop: 0,
          }}
          transition={{ duration: 0.2 }}
        >
          <span className={styles.errorMessage}>{customError}</span>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
))(({ theme, customTheme }) => ({
  '&.MuiTextField-root': {
    border: 'none',
    borderRadius: 10,
    width: '100%',
  },

  '& .MuiInputBase-input': {
    fontFamily: 'Inter',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.13px',
  },
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: `1px solid ${
      customTheme === 'black' ? 'rgba(255, 255, 255, 0.56)' : 'rgba(23, 33, 43, 0.1)'
    }`,
    transition: 'all 0.3s',
    borderRadius: 10,

    '&.Mui-error': {
      border: '1px solid rgba(255, 61, 77, 0.36)',
      background: 'rgba(255, 61, 77, 0.02)',
    },
    '&.Mui-focused': {
      border: '1px solid #b1f042',
      backgroundColor: 'transparent',

      '&.Mui-error': {
        border: '1px solid rgba(255, 61, 77, 0.72)',
      },
    },

    '&:hover': {
      //border: `1px solid ${
      //  customTheme === 'black' ? 'rgba(255, 255, 255, 0.56)' : 'rgba(23, 33, 43, 0.16)'
      //}`,
      backgroundColor: 'transparent',
    },
  },

  '& label': {
    color: '#7D8288',
    fontSize: 15,
    fontFamily: 'Inter',
    top: 3,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& label.Mui-focused': {
    color: '#7D8288;',
  },
  '& label.Mui-error': {
    color: '#7D8288;',
  },
}))
