export interface ICardValue {
  number: string
  expire: string
}

export enum CardInputStepEnum {
  NUMBER,
  EXPIRE,
  CVV,
}
