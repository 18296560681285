import React, { useEffect, useRef, useState } from 'react'
import { IGetTds } from '../../../../services/invoice/invoice.service.types'

export const TdsForm = ({ formData }: { formData: IGetTds }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)

  useEffect(() => {
    const meta = document.createElement('meta')
    meta.name = 'referrer'
    meta.content = 'no-referrer'
    document.getElementsByTagName('head')[0].appendChild(meta)
    setCanSubmit(true)
  }, [])

  useEffect(() => {
    if (canSubmit) {
      formRef.current && formRef.current.submit()
    }
  }, [canSubmit])

  return (
    <div>
      {canSubmit && (
        <form method="post" ref={formRef} action={formData?.url}>
          <input name="PaReq" defaultValue={formData?.pareq} type="hidden" />
          <input name="MD" defaultValue={formData?.md} type="hidden" />
          <input name="TermUrl" defaultValue={formData?.term} type="hidden" />
          <input type="submit" />
        </form>
      )}
    </div>
  )
}
