import React from 'react'
import { ReactComponent as IconBack } from 'assets/images/icons/icon-back.svg'
import { IMethodHeader } from './MethodHeader.types'

import styles from './MethodHeader.module.scss'
import { AvailableMethodsEnum } from '../../helpers/types/availableMethods.type'
import { useNavigate } from 'react-router-dom'
import {
  SidebarStatusEnum,
  useSidebar,
} from '../Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { useTranslation } from 'react-i18next'
import { useInvoice } from '../Layout/Invoice/InvoiceContext/InvoiceContext'

const serviceName: Record<AvailableMethodsEnum, string> = {
  [AvailableMethodsEnum.CARD]: 'components:payment_methods.Оплата картой',
  [AvailableMethodsEnum.SBP]: 'components:payment_methods.СБП',
  [AvailableMethodsEnum.BITCOIN]: 'Bitcoin',
  [AvailableMethodsEnum.PERFECT]: 'Perfect Money',
  [AvailableMethodsEnum.QIWI]: 'components:payment_methods.Оплата Qiwi',
  [AvailableMethodsEnum.YOOMONEY]: 'components:payment_methods.ЮMoney',
  [AvailableMethodsEnum.DASH]: 'DASH',
  [AvailableMethodsEnum.ETHEREUM]: 'Ethereum',
  [AvailableMethodsEnum.LITECOIN]: 'Litecoin',
  [AvailableMethodsEnum.TON]: 'TON',
  [AvailableMethodsEnum.TRX]: 'TRX',
  [AvailableMethodsEnum.USDT_ERC20]: 'USDT ERC20',
  [AvailableMethodsEnum.USDT_TRC20]: 'USDT TRC20',
}

export const MethodHeader = ({ service, additionalContent }: IMethodHeader) => {
  const { t } = useTranslation(['components'])
  const { setSidebarStatus } = useSidebar()
  const { servicesCount, isWaitingForPayment } = useInvoice()
  const navigate = useNavigate()

  if (!service) {
    return null
  }

  return (
    <div className={styles.stack}>
      <div className={styles.container}>
        {Number(servicesCount) > 1 && !isWaitingForPayment && (
          <button
            className={styles.iconBack}
            onClick={() => {
              setSidebarStatus(SidebarStatusEnum.EXPANDED)
              navigate(-1)
            }}
          >
            <IconBack />
          </button>
        )}
        <img
          className={styles.iconService}
          src={`/img/icon-${service}.svg`}
          alt={`icon-${service}`}
        />
        <h2 className={styles.title}>{t(serviceName[service as AvailableMethodsEnum])}</h2>
      </div>
      {additionalContent}
    </div>
  )
}
