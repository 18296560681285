import React, { useState } from 'react'
import styles from './Checkout.module.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Footer } from '../Invoice/Footer/Footer'
import { MethodHeader } from 'components/MethodHeader/MethodHeader'
import { AvailableMethodsEnum } from 'helpers/types/availableMethods.type'
import { Button } from 'ui-lava/Button/Button'
import { replaceAnyCharactersExceptNumberAndPlus } from 'helpers/regExpHelper'
import { InputLabel } from '../../../ui-lava/Input/InputLabel/InputLabel'
import { useMutation } from '@tanstack/react-query'
import { InvoiceService } from 'services/invoice/invoice.service'
import { IGetInvoiceWithCheckout } from 'services/invoice/invoice.service.types'
import { useGoogleRecaptcha } from '../../../hooks/useGoogleRecaptcha'
import { InputPhone } from 'ui-lava/Input/InputPhone/InputPhone'
import { Helmet } from 'react-helmet'
import { useInvoice } from '../Invoice/InvoiceContext/InvoiceContext'

const Checkout = () => {
  const { checkoutId } = useParams()
  const navigate = useNavigate()
  const { execute } = useGoogleRecaptcha()
  const { t } = useTranslation(['components', 'ui'])
  const { service } = useInvoice()
  const descriptionContent = `${t('components:description.Оплата заказа')}${service.metaName}`

  const [phoneNumberData, setPhoneNumberData] = useState({
    phone_number: '',
    error: '',
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { mutate: mutateCheckout, isLoading } = useMutation(
    ['checkRedirect'],
    (data: IGetInvoiceWithCheckout) => InvoiceService.getInvoiceWithCheckout(data),
    {
      onSuccess: ({ data }) => {
        navigate(`/${data.data.id}`)
      },
    }
  )

  const handlePhoneChange = (value: string) => {
    setPhoneNumberData({
      phone_number: value.replace(replaceAnyCharactersExceptNumberAndPlus, ''),
      error: '',
    })
  }

  const handleSubmitForm = async (data: FieldValues) => {
    const re_captcha_token = await execute('submit')

    mutateCheckout({
      checkout_id: checkoutId ?? '',
      amount: +data.amount,
      phone_number: phoneNumberData.phone_number,
      re_captcha_token,
    })
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={service.favIcon} />
        <title>
          {t('components:title.Оплата заказа')} {service.metaName}
        </title>
        <meta name="description" content={descriptionContent}></meta>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
            <MethodHeader
              service={AvailableMethodsEnum.CARD}
              additionalContent={
                <span>{t('components:card_form.Укажите номер телефона ND Charger')}</span>
              }
            />
            <InputPhone
              className={styles.input}
              specialLabel={t('components:card_form.Телефон')}
              inputProps={{
                id: 'filled',
                name: 'phone_number',
              }}
              country="uz"
              value={phoneNumberData.phone_number}
              onChange={handlePhoneChange}
              error={phoneNumberData.error}
            />

            <Controller
              control={control}
              name="amount"
              defaultValue=""
              rules={{
                required: t('components:card_form.Введите сумму пополнения'),
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: t('components:card_form.Введите корректную сумму пополнения'),
                },
                min: {
                  value: 1000,
                  message: t('components:card_form.Минимальная сумма вывода 1000'),
                },
                max: {
                  value: 6900000,
                  message: t('components:card_form.Максимальная сумма вывода 6900000'),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputLabel
                    id="filled-basic"
                    label={t('components:card_form.Введите сумму пополнения')}
                    variant="filled"
                    inputMode="numeric"
                    autoComplete="transaction-amount"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                    customError={errors.amount?.message as string}
                    className={styles.input}
                  />
                )
              }}
            />
            <Button disabled={!isValid || isLoading} type="submit" className={styles.button}>
              {t('ui:buttons.Перейти к оплате')}
            </Button>
          </form>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Checkout
