import React from 'react'
import { useTranslation } from 'react-i18next'
import { MethodHeader } from '../../../components/MethodHeader/MethodHeader'
import { PaymentLoader } from '../../../components/PaymentLoader/PaymentLoader'
import { PciSecure } from '../../../components/PaymentOffer/PciSecure/PciSecure'
import styles from './CreatedStatus.module.scss'

export const CreatedStatus = ({ serviceName }: { serviceName: string }) => {
  const { t } = useTranslation(['components'])
  return (
    <div className={styles.container}>
      <MethodHeader service={serviceName} />
      <p className={styles.text}>{t('components:status_page.Не закрывайте и не обновляйте')}</p>

      <div className={styles.content}>
        <PaymentLoader
          text={t('components:payment_loader.Обрабатываем ваш платёж')}
          subText={t('components:payment_loader.Это может занять до 5 минут')}
        />
      </div>

      <PciSecure />
    </div>
  )
}
