import React, { ChangeEvent, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { IResponseError } from 'types/IResponse'
import styles from './CardCodeForm.module.scss'
import { InputLabel } from 'ui-lava/Input/InputLabel/InputLabel'
import { Button } from 'ui-lava/Button/Button'
import { useTranslation } from 'react-i18next'

interface ICardCodeFormProps {
  codeUrl: string
}

const CardCodeForm = ({ codeUrl }: ICardCodeFormProps) => {
  const navigate = useNavigate()
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { t } = useTranslation(['components', 'ui'])

  const [codeInfo, setCodeInfo] = useState<{
    code: number | string | null
    error: string
  }>({
    code: null,
    error: '',
  })

  const { mutate: checkCode } = useMutation(
    ['checkCode'],
    () => axios.put(codeUrl ?? '', { code: codeInfo.code }),
    {
      onSuccess: () => {
        navigate(`/${invoiceId}/status`)
      },
      onError: (error: IResponseError) =>
        setCodeInfo((prev) => ({ ...prev, error: error.response?.data.error ?? '' })),
    }
  )

  const handleSubmitCode = (e: React.FormEvent) => {
    e.preventDefault()

    if (codeInfo.code !== null && !validateCode(+codeInfo.code)) return

    checkCode()
  }

  const validateCode = (value: number | null) => {
    if (!value || value.toString().length !== 6) {
      setCodeInfo((prev) => ({
        ...prev,
        error: t('components:card_form.Введите корректный код'),
      }))
      return false
    } else {
      setCodeInfo((prev) => ({
        ...prev,
        error: '',
      }))
      return true
    }
  }

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (!/^\d+$/.test(e.target.value)) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1)
    }

    setCodeInfo({
      error: '',
      code: e.target.value,
    })
  }

  return (
    <form onSubmit={handleSubmitCode} id="codeForm">
      <InputLabel
        id="filled"
        label={t('components:card_form.Код')}
        variant="filled"
        name="code"
        type="text"
        inputMode="numeric"
        autoComplete="one-time-code"
        inputProps={{ maxLength: 6 }}
        value={codeInfo.code}
        onChange={handleChangeCode}
        onPaste={(e) => {
          if (!/^\d+$/.test(e.clipboardData.getData('text'))) {
            e.preventDefault()
          }
        }}
        customError={codeInfo.error}
      />
      <Button className={styles.button} form="codeForm">
        {t('ui:buttons.Подтвердить')}
      </Button>
    </form>
  )
}

export default CardCodeForm
