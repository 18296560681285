import { ToggleButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&.MuiToggleButtonGroup-root': {
    width: '100%',
    backgroundColor: 'rgba(23, 33, 43, 0.04)',
    borderRadius: '10px',
    padding: '3px',
  },
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.MuiToggleButton-root': {
      borderRadius: '7px',
      width: '100%',
      padding: '16px',
      textTransform: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '17px',
      lineHeight: '24px',
      color: '#17212B',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      borderRadius: '7px',
      backgroundColor: '#FFFFFF',

      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      // borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      // borderRadius: theme.shape.borderRadius,
    },
  },

  '& .MuiButtonBase-root': {
    color: 'red',
  },
}))
