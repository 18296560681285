import { useEffect, useState } from 'react'
import { detectCardType } from '../helpers/validations'
export const useCardNumberMask = () => {
  const [cardNumberMask, setCardNumberMask] = useState<any>([
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ])
  const [service, setService] = useState<string>('')

  const onNumberInput = (number: string) => {
    setService(detectCardType(number) || '')
  }

  useEffect(() => {
    if (!service || service === 'maestro') {
      setCardNumberMask([
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ])
    } else
      setCardNumberMask([
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ])
  }, [service])

  return { cardNumberMask, service, onNumberInput }
}
