import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { InvoiceService } from '../../../services/invoice/invoice.service'
import { IGetMultiPerfect, IGetTds } from '../../../services/invoice/invoice.service.types'
import { PaymentLoader } from '../../PaymentLoader/PaymentLoader'
import { StatusPayment } from '../../StatusPayment/StatusPayment'
import { PerfectConfirmationForm } from './PerfectConfirmationForm/PerfectConfirmationForm'
import { TdsForm } from './TdsForm/TdsForm'
import styles from './PaymentConfirmation.module.scss'
import { Button } from '../../../ui-lava/Button/Button'
import { IResponseError } from '../../../types/IResponse'

//TODO: REFACTOR REFACTOR REFACTOR REFACTOR

export const PaymentConfirmation = () => {
  const { t } = useTranslation(['components'])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [tdsData, setTdsData] = useState<IGetTds>({})
  const [perfectData, setPerfectData] = useState<IGetMultiPerfect>({})

  const perfectFormRef = useRef() as React.MutableRefObject<HTMLFormElement>

  const { data: data_getTds, isLoading } = useQuery(
    ['getTds'],
    () => InvoiceService.getTds(searchParams?.get('t') ?? ''),
    {
      onSuccess: ({ data }) => {
        data?.data && setTdsData(data?.data)
      },

      enabled: !!searchParams.get('t'),
    }
  )

  const { data: data_getPerfect, isLoading: isLoading_getPerfect } = useQuery(
    ['getPerfect'],
    () => InvoiceService.getMultiPerfect(searchParams?.get('p') ?? ''),
    {
      onSuccess: ({ data }) => {
        data?.data && setPerfectData(data?.data)
      },

      onError: () => {
        // setTestData('123')
      },
      enabled: !!searchParams.get('p'),
    }
  )

  const {
    data: data_getYooMoney,
    isLoading: isLoading_getYooMoney,
    isError: isError_getYooMoney,
    error: error_getYooMoney,
  } = useQuery(
    ['getMultiYooMoney'],
    () =>
      InvoiceService.getMultiYooMoney({
        internalId: searchParams?.get('internalId') ?? '',
        code: searchParams?.get('code') ?? undefined,
        error: searchParams?.get('error') ?? undefined,
      }),
    {
      onSuccess: () => {
        navigate(`/${searchParams?.get('invoiceId')}/status`)
      },
      onError: (_error: IResponseError) => null,
      // onError: () => {
      //   navigate(`/${searchParams?.get('invoiceId')}`)
      // },
      enabled: !!searchParams.get('internalId'),
    }
  )

  // useEffect(() => {
  //   if (tdsData?.url) {
  //     tdsFormRef?.current?.submit()
  //   }
  // }, [tdsData])

  useEffect(() => {
    if (perfectData?.id) {
      perfectFormRef?.current?.submit()
    }
  }, [perfectData])

  const getConfirmationContent = () => {
    if (searchParams?.get('t')) {
      return <TdsForm formData={tdsData} />
    }

    if (searchParams?.get('p')) {
      return <PerfectConfirmationForm formRef={perfectFormRef} formData={perfectData} />
    }
  }

  if (isError_getYooMoney) {
    return (
      <div className={styles.layout}>
        <div className={styles.invoiceBody}>
          <StatusPayment
            type="error"
            text={
              error_getYooMoney?.response?.data?.error ??
              t(
                'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку'
              )
            }
            title={t('components:status_payment.Произошла ошибка')}
            action={
              <Button onClick={() => navigate(`/${searchParams?.get('invoiceId')}`)}>
                {t('components:status_payment.Попробовать еще раз')}
              </Button>
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.layout}>
      <div className={styles.invoiceBody}>
        {(!isLoading || !isLoading_getPerfect) && getConfirmationContent()}
        <PaymentLoader text={t('components:payment_loader.Идет переадресация')} />
      </div>
    </div>
  )
}
