import React from 'react'
import styles from './SelectMethodPlug.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export const SelectMethodPlug = () => {
  const { t } = useTranslation(['components'])
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <div className={styles.icon}></div>
          <div className={styles.text}></div>
        </div>
        <div className={cn(styles.item, styles.active)}>
          <div className={styles.icon}></div>
          <div className={styles.text}></div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}></div>
          <div className={styles.text}></div>
        </div>

        <p className={styles.description}>{t('components:methods_plug.Выберите метод оплаты')}</p>
      </div>
    </div>
  )
}
