import React from 'react'
import { CircularProgress } from '@mui/material'
import styles from './InvoiceLayoutSkeleton.module.scss'

export const InvoiceLayoutSkeleton = () => {
  return (
    <div className={styles.container}>
      <CircularProgress size={96} sx={{ color: 'var(--PAA_Brand_Primary)' }} />
    </div>
  )
}
