import React from 'react'
import './Footer.scss'
import { DropdownLanguage } from '../Form/Dropdown/DropdownLanguage/DropdownLanguage'
import { useTranslation } from 'react-i18next'
// import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation(['components'])
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer__info">
          <div className="info__company">
            <span className="company__text">{t('components:footer.ООО «Платёжные Системы»')}</span>
          </div>
          {/*<div className="info__link">
            <div className="link__public-offer">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://enot.io/oferta.pdf?v=2"
                className="public-offer__text"
              >
                {t('components:footer.Договор оферты')}
              </a>
            </div>
            <div className="link__support">
              <a href="https://t.me/Enot_support_bot" className="support__text">
                {t('components:footer.Поддержка')}
              </a>
            </div>
          </div>*/}
          <div className="info__language">
            <DropdownLanguage />
          </div>
        </div>
        {/*<div className="footer__tel">*/}
        {/*  /!*<div className="tel__number">*!/*/}
        {/*  /!*  <a href="tel:88005115568" className="number__text">*!/*/}
        {/*  /!*    8 800 511 55 68*!/*/}
        {/*  /!*  </a>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  /!*<div className="tel__description">*!/*/}
        {/*  /!*  <span className="description__text">*!/*/}
        {/*  /!*    {t('components:footer.Для звонков по России — бесплатно')}*!/*/}
        {/*  /!*  </span>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
      </div>
    </footer>
  )
}
