import React, {
  ChangeEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  useRef,
  useState,
} from 'react'
import styles from './CardInput.module.scss'
import { useCardNumberMask } from 'hooks/useCardNumberMask'
import InputMask from 'react-input-mask'
import { lunhAlgorithm } from 'functions/lunhAlgorithm'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import MaskedInput from 'react-text-mask'
import { CardInputStepEnum } from './CardInput.types'
import { useTranslation } from 'react-i18next'
import { ICardFormValue } from '../../../views/Invoice/Methods/Card/CardForm/CardForm'
import { ReactComponent as IconMasterCard } from 'assets/images/icons/icon-mastercard.svg'
import { ReactComponent as IconVisa } from 'assets/images/icons/icon-visa.svg'
import { ReactComponent as IconMir } from 'assets/images/icons/icon-mir.svg'
import { ReactComponent as IconMaestro } from 'assets/images/icons/icon-maestro.svg'
import { ReactComponent as IconCard } from 'assets/images/icons/icon-unknown-card.svg'

const variantsInput = {
  expanded: {
    minWidth: '25%',
    width: '30%',
    // x: 0,
  },
  collapsed: {
    width: 0,
    minWidth: 0,
    // x: 150,
  },
}

const cardIcons = {
  visa: <IconVisa />,
  mastercard: <IconMasterCard />,
  mir: <IconMir />,
  maestro: <IconMaestro />,
  unknown: <IconCard />,
}

const variantsCard = {
  expanded: (service: string) => ({
    width: '100%',
    paddingLeft: service ? 42 : 0,
  }),
  collapsed: {
    width: '30%',
    paddingLeft: 42,
  },
}

const variantsIcon = {
  hide: {
    width: 0,
    x: -16,
  },
  show: {
    width: 'auto',
    x: 16,
  },
}
export const CardInput = ({
  value,
  setValue,
  errors,
  setErrors,
  isCvvShow,
  nextRef,
}: {
  value: ICardFormValue
  isCvvShow: boolean
  setValue: any
  errors: any
  setErrors: any
  nextRef: any
}) => {
  const { onNumberInput, cardNumberMask, service } = useCardNumberMask()
  const [cardInputStep, setCardInputStep] = useState<CardInputStepEnum>(CardInputStepEnum.NUMBER)
  // const [cardError, setCardError] = useState<string>('')
  const [expireDateMask, setExpireDateMask] = useState([/[0-1]/, /[0-9]/, '/', /[2-9]/, /[0-9]/])
  const { t } = useTranslation(['components', 'ui'])

  const numberRef = useRef<HTMLInputElement | any>(null)
  const expireRef = useRef<HTMLInputElement>(null)
  const cvvRef = useRef<HTMLInputElement>(null)

  // const handleChangeCard = (value: any) => {
  //   setValue((prev) => ({ ...prev, number: value }))
  // }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors((prev: any) => ({ ...prev, card: '' }))
    if (e.target.id === 'cardNumber') {
      setValue((prev: any) => ({
        ...prev,
        expire: '',
        number: e.target.value.replace(/\D/g, ''),
      }))
      onNumberInput(e.target.value.replace(/\D/g, ''))

      if (e.target.value.replace(/\D/g, '')?.length > 15) {
        if (lunhAlgorithm(e.target.value.replace(/\D/g, ''))) {
          setCardInputStep(CardInputStepEnum.EXPIRE)
          expireRef?.current?.focus()
        } else {
          // setCardError('Введите корректный номер карт')
          setErrors((prev: any) => ({
            ...prev,
            card: t('components:card_form.Неправильный номер карты'),
          }))
        }
      }
      return
    }

    if (e.target.id === 'cardExpire') {
      const [month, year] = e.target.value.split('/')

      if (+month[0] > 0) {
        if (+year?.[0] === 2) {
          setExpireDateMask([/[0-1]/, /[0-2]/, '/', /[2-9]/, /[2-9]/])
        } else {
          setExpireDateMask([/[0-1]/, /[0-2]/, '/', /[2-9]/, /[0-9]/])
        }
      } else {
        if (+year?.[0] === 2) {
          setExpireDateMask([/[0-1]/, /[1-9]/, '/', /[2-9]/, /[2-9]/])
        } else {
          setExpireDateMask([/[0-1]/, /[1-9]/, '/', /[2-9]/, /[0-9]/])
        }
      }

      setValue((prev: any) => ({ ...prev, expire: e.target.value }))
      return
    }

    // handleChange(cardValue)
  }

  const handleKeyUp = (e: any) => {
    const value = e.target.value.replace(/\D/g, '')

    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 37 || e.keyCode === 39) {
      return
    }
    switch (e.target.id) {
      case 'cardNumber':
        if (value?.length > 15) {
          if (lunhAlgorithm(value)) {
            setCardInputStep(CardInputStepEnum.EXPIRE)
            expireRef?.current?.focus()
          } else {
            // setCardError('Введите корректный номер карт')
            setErrors((prev: any) => ({
              ...prev,
              card: t('components:card_form.Неправильный номер карты'),
            }))
          }
        }
        break
      case 'cardExpire':
        {
          if (value.length < 4) return

          cvvRef.current?.focus()
        }
        break
      default:
        return
    }
  }

  const handleClick: MouseEventHandler<HTMLInputElement> = (event) => {
    //@ts-ignore
    event.target.value = event.target.value.trim()
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.target.value = event.target.value.trim()
    }
  }

  const onCvvChange = (event: ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, card: '' })

    const cvv = event.target.value
    setValue({ ...value, cvv })
  }

  return (
    <div className={styles.cardWrapper}>
      <div
        className={cn(styles.cardInput, { [styles.error]: errors?.card !== '' })}
        // contentEditable
        tabIndex={0}
        onBlur={() => {
          if (value?.number?.length > 15) {
            if (lunhAlgorithm(value?.number)) {
              setCardInputStep(CardInputStepEnum.EXPIRE)
              //  expireRef?.current?.focus()
            } else {
              // setCardError('Введите корректный номер карт')
              setErrors((prev: any) => ({
                ...prev,
                card: t('components:card_form.Неправильный номер карты'),
              }))
            }
          }
        }}
      >
        <AnimatePresence>
          <motion.div
            variants={variantsIcon}
            initial="hide"
            animate="show"
            exit="hide"
            key={service}
            transition={{ bounce: false, duration: 0.23 }}
            custom={service}
          >
            <div className={styles.cardIcon}>
              {
                //@ts-ignore
                value.number.length > 4 && !service ? cardIcons.unknown : cardIcons[service]
              }
            </div>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            className={cn(styles.inputLabel, {
              [styles.active]: cardInputStep > 0,
            })}
            // onClick={() => setCardInputStep(CardInputStepEnum.NUMBER)}
            onFocus={() => setCardInputStep(CardInputStepEnum.NUMBER)}
            animate={cardInputStep > 0 ? 'collapsed' : 'expanded'}
            exit="collapsed"
            initial="expanded"
            transition={{ bounce: false, duration: 0.23 }}
            variants={variantsCard}
            custom={service}
          >
            <MaskedInput
              mask={cardNumberMask}
              autoComplete="cc-number"
              inputMode="numeric"
              id="cardNumber"
              // value={value.number}
              value={cardInputStep > 0 ? value?.number?.slice(-4) : value.number}
              onChange={onChange}
              onKeyUp={handleKeyUp}
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              placeholderChar={'\u2000'}
              showMask={false}
              type="text"
              //@ts-ignore
              ref={numberRef}
              className={styles.cardInputInner}
            />
            <label
              className={cn(styles.label, { [styles.active]: value?.number !== '' })}
              htmlFor="cardNumber"
            >
              {t('components:card_form.Номер карты')}
            </label>
          </motion.div>
        </AnimatePresence>

        <AnimatePresence>
          <motion.div
            className={styles.inputLabel}
            variants={variantsInput}
            animate={cardInputStep > 0 ? 'expanded' : 'collapsed'}
            exit="collapsed"
            initial="collapsed"
            transition={{ bounce: false, duration: 0.23 }}
            style={{ overflow: 'hidden' }}
            onClick={() => setCardInputStep(CardInputStepEnum.EXPIRE)}
          >
            <InputMask
              mask={expireDateMask}
              autoComplete="cc-exp"
              maskPlaceholder={''}
              value={value.expire}
              beforeMaskedStateChange={({ currentState, nextState }) => {
                if (currentState && currentState.value.length === 7) {
                  const [mm, year] = currentState.value.split('/')
                  return {
                    ...currentState,
                    value: `${mm}/${year.substring(2)}`,
                  }
                }
                return nextState
              }}
              onChange={onChange}
            >
              <input
                ref={expireRef}
                id="cardExpire"
                placeholder={t('components:card_form.ММ/ГГ')}
                type="tel"
                onKeyUp={handleKeyUp}
                onKeyDown={(e) => {
                  if (e.target.value?.length > 0) {
                    return
                  } else {
                    if (e.key === 'Backspace' || e.key === 'Delete') {
                      // numberRef?.current?.focus()
                      numberRef?.current?.inputElement.focus()

                      setCardInputStep(CardInputStepEnum.NUMBER)
                    }
                  }
                }}
              />
            </InputMask>
            <label
              className={cn(styles.label, { [styles.active]: value?.expire !== '' })}
              htmlFor="cardExpire"
            >
              {t('components:card_form.Срок')}
            </label>
          </motion.div>
        </AnimatePresence>
        {isCvvShow ? (
          <AnimatePresence>
            <motion.label
              className={styles.inputLabel}
              variants={variantsInput}
              animate={cardInputStep > 0 ? 'expanded' : 'collapsed'}
              exit="collapsed"
              initial="collapsed"
              transition={{ bounce: false, duration: 0.23 }}
              onClick={() => setCardInputStep(CardInputStepEnum.CVV)}
            >
              <InputMask
                //@ts-ignore type mistake
                ref={cvvRef}
                id="cardCvv"
                className={cn(styles.input)}
                type="password"
                inputMode="numeric"
                autoComplete="cc-csc"
                placeholder="000"
                value={value.cvv}
                onChange={onCvvChange}
                mask="999"
                maskPlaceholder=""
              />
              <label
                className={cn(styles.label, { [styles.active]: value?.cvv !== '' })}
                htmlFor="cardCvv"
              >
                {t('components:card_form.Код карты')}
              </label>
            </motion.label>
          </AnimatePresence>
        ) : null}
      </div>

      <AnimatePresence>
        {errors?.card && (
          <motion.div
            initial={{
              opacity: 0.1,
              height: 0,
              marginTop: 0,
            }}
            animate={{
              opacity: 1,
              height: 'auto',
              marginTop: 4,
            }}
            exit={{
              opacity: 0.1,
              height: 0,
              marginTop: 0,
            }}
            transition={{ duration: 0.2 }}
          >
            <div className={styles.error}>{errors?.card}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
