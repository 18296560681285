import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { IModalWindow } from './ModalWindow.types'
import styles from './ModalWindow.module.scss'
import { ReactComponent as IconClose } from 'assets/images/ui/icon-close.svg'
import { IconButton } from '../Button/IconButton/IconButton'
import { AnimatePresence } from 'framer-motion'
import { useScrollLock } from '../../hooks/useScrollLock'
import { viewportCalc } from '../../helpers/viewport_calc.helper'

export const ModalWindow = ({ isOpened, children, onClose }: IModalWindow) => {
  const { lockScroll, unlockScroll } = useScrollLock()
  const [mouseEvent, setMouseEvent] = useState<boolean>(false)

  useEffect(() => {
    viewportCalc()
    isOpened ? lockScroll() : unlockScroll()
  }, [isOpened])

  const escFunction = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', (event: any) => escFunction(event), false)
    return () => {
      document.removeEventListener('keydown', (event: any) => escFunction(event), false)
    }
  }, [])

  return createPortal(
    <AnimatePresence exitBeforeEnter>
      {isOpened && (
        <>
          <div
            className={styles.overlay}
            onClick={() => {
              if (mouseEvent) {
                onClose()
              }
              setMouseEvent(true)
            }}
          >
            <div
              className={styles.modal}
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={() => {
                setMouseEvent(true)
              }}
              onMouseDown={() => {
                setMouseEvent(false)
              }}
              onMouseUp={() => {
                setMouseEvent(true)
              }}
            >
              <div className={styles.modalControl}>
                <div className={styles.closeButton}>
                  <IconButton buttonStyle="ghost" onClick={onClose}>
                    <IconClose />
                  </IconButton>
                </div>
              </div>
              <div className={styles.modalContent}>{children}</div>
            </div>
          </div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById('modal-container') as HTMLElement
  )
}
