import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { InputLabel } from 'ui-lava/Input/InputLabel/InputLabel'
import { InputPhone } from 'ui-lava/Input/InputPhone/InputPhone'
import { CardInput } from '../../../../../ui-lava/Input/CardInput/CardInput'
import { useInvoice } from '../../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { getCurrencySign } from '../../../../../helpers/getCurrencySign'
import { Button } from 'ui-lava/Button/Button'
import { lunhAlgorithm } from 'functions/lunhAlgorithm'
import { Patterns } from 'enums/patterns.const'
import { useMethodsContext } from 'components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { deleteSpaces } from '../../../../../helpers/inputDeleteSpaces'
import { useQuery } from '@tanstack/react-query'
import { InvoiceService } from 'services/invoice/invoice.service'
import { cyrillicToLatin } from 'helpers/cyryllicToLatin'
import { replaceAnyCharactersExceptNumberAndPlus } from 'helpers/regExpHelper'
import { isValidPhone } from 'helpers/validations'

import styles from './CardForm.module.scss'

export interface PaymentCardInputValues {
  cardNumber: string
  expireMonth: string
  expireYear: string
  isValid: boolean
}

export interface ICardFormValue {
  number: string
  expire: string
  email: string
  cardHolder?: string
  phone_number?: string
  cvv?: string
}

export const CardForm = ({ onSubmitCard }: { onSubmitCard: any }) => {
  const { stepOneData } = useMethodsContext()
  const [isFormValid, setIsFormValid] = useState<{
    email: boolean
    cardHolder?: boolean
    phone_number: boolean
  }>({
    cardHolder: false,
    // email: stepOneData?.use_email ? false : true,
    email: false,
    phone_number: false,
  })
  const { invoiceAmount } = useInvoice()

  const invoiceSumToPay = useMemo(
    () => `${invoiceAmount.amount} ${getCurrencySign(invoiceAmount.currency)}`,
    [invoiceAmount]
  )
  const [errors, setErrors] = useState<{
    email: string
    card: string
    cardHolder?: string
    phone_number: string
    cvv: string
  }>({
    email: '',
    cardHolder: '',
    card: '',
    phone_number: '',
    cvv: '',
  })
  const { t } = useTranslation(['components', 'ui'])
  const [cardFormValue, setCardFormValue] = useState<ICardFormValue>({
    cardHolder: '',
    number: '',
    expire: '',
    email: '',
    phone_number: '',
    cvv: '',
  })
  const cardHolderRef = useRef<HTMLInputElement>(null)

  const checkCardData = useQuery(
    ['checkCard', cardFormValue.number],
    () =>
      InvoiceService.checkCard({
        secret_key: stepOneData?.signature ?? '',
        card_number: cardFormValue.number,
      }),
    {
      enabled:
        !!stepOneData?.signature &&
        cardFormValue.number.length === 16 &&
        !!lunhAlgorithm(cardFormValue.number),
      select: (data) => data.data.data,
      retry: false,
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (
      !validateCard({
        number: cardFormValue.number,
        expire: cardFormValue.expire,
      }) ||
      (checkCardData.data?.show_cvv_input && !validateCode(cardFormValue.cvv)) ||
      (checkCardData.data?.show_full_name_input && !validateCardHolder(cardFormValue.cardHolder)) ||
      (stepOneData?.use_email && !validateEmail(cardFormValue.email)) ||
      (cardFormValue.phone_number && !validatePhoneNumber(cardFormValue.phone_number))
    ) {
      return
    }

    const monthYearFromExpire = cardFormValue?.expire?.split('/')
    onSubmitCard(
      {
        cardNumber: cardFormValue?.number ?? undefined,
        expireMonth: monthYearFromExpire[0] ?? undefined,
        expireYear: monthYearFromExpire[1] ?? undefined,
        email: cardFormValue?.email === '' ? undefined : cardFormValue.email,
        first_name: cardFormValue?.cardHolder?.split(' ')[0] || undefined,
        last_name: cardFormValue?.cardHolder?.split(' ')[1] || undefined,
        phone_number:
          cardFormValue.phone_number && cardFormValue.phone_number?.length
            ? cardFormValue.phone_number
            : undefined,
        cvv: cardFormValue.cvv && cardFormValue.cvv?.length ? cardFormValue.cvv : undefined,
      },
      checkCardData.data?.secret_key
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.name !== 'cardHolder') {
      deleteSpaces(e)
    }

    if (e.target.name === 'cardHolder') {
      setErrors({ ...errors, cardHolder: '' })

      setCardFormValue({
        ...cardFormValue,
        cardHolder: cyrillicToLatin(e?.target?.value)
          ?.toUpperCase()
          .replace(/\s{2,}/g, ' '),
      })
      return
    }

    if (e.target.name === 'email') {
      setErrors({ ...errors, email: '' })

      setCardFormValue({
        ...cardFormValue,
        email: e.target.value,
      })
    }
  }

  const handlePhoneChange = (value: string) => {
    setErrors({ ...errors, phone_number: '' })

    setCardFormValue({
      ...cardFormValue,
      phone_number: value.replace(replaceAnyCharactersExceptNumberAndPlus, ''),
    })
  }

  const validateCardHolder = (value?: string) => {
    const re = /([a-z]{1,})\s([a-z]{1,})/i

    if (!value) {
      setIsFormValid({
        ...isFormValid,
        cardHolder: false,
      })
      setErrors({ ...errors, cardHolder: t('components:card_form.Введите корректное имя') })
      return false
    }

    if (value?.length < 3) {
      setIsFormValid({
        ...isFormValid,
        cardHolder: false,
      })
      setErrors({ ...errors, cardHolder: t('components:card_form.Введите корректное имя') })
      return false
    } else if (!re.test(value)) {
      setIsFormValid({
        ...isFormValid,
        cardHolder: false,
      })
      setErrors({ ...errors, cardHolder: t('components:card_form.Введите корректные данные') })
      return false
    } else {
      setIsFormValid({
        ...isFormValid,
        cardHolder: true,
      })
      return true
    }
  }

  const validateEmail = (value: string) => {
    if (value?.length < 1 && !stepOneData?.is_required_email) {
      return true
    }

    if (value?.length < 3) {
      setIsFormValid({
        ...isFormValid,
        email: false,
      })
      setErrors({ ...errors, email: t('components:card_form.Неверный email') })
      return false
    } else if (!Patterns.EMAIL.test(value)) {
      setIsFormValid({
        ...isFormValid,
        email: false,
      })
      setErrors({ ...errors, email: t('components:card_form.Введите корректный email адрес') })
      return false
    } else {
      setIsFormValid({
        ...isFormValid,
        email: true,
      })
      return true
    }
  }

  const validateCard = ({ number, expire }: { number: string; expire: string }) => {
    if (number?.length < 16) {
      setErrors({ ...errors, card: t('components:card_form.Введите корректный номер карты') })
      return false
    }

    if (!lunhAlgorithm(number)) {
      setErrors({ ...errors, card: t('components:card_form.Введите корректный номер карты') })
      return false
    }

    if (expire?.length < 5) {
      setErrors({ ...errors, card: t('components:card_form.Введите корректную дату') })
      return false
    }

    return true
  }

  const validateCode = (code?: string) => {
    if (code != undefined && code?.length < 3) {
      setErrors({ ...errors, card: t('components:card_form.Введите корректный код') })
      return false
    }
    return true
  }

  const validatePhoneNumber = (value: string) => {
    if (!isValidPhone(value)) {
      setIsFormValid({
        ...isFormValid,
        phone_number: false,
      })
      setErrors({
        ...errors,
        phone_number: t('components:card_form.Введите корректный номер телефона'),
      })
      return false
    } else {
      setIsFormValid({
        ...isFormValid,
        phone_number: true,
      })
      return true
    }
  }

  return (
    <div className={cn(styles.wrapper)}>
      <form className={styles.inputs} onSubmit={handleSubmit} id="cardForm">
        <div className={styles.input}>
          <CardInput
            value={cardFormValue}
            setValue={setCardFormValue}
            errors={errors}
            setErrors={setErrors}
            isCvvShow={!!checkCardData.data?.show_cvv_input}
            nextRef={cardHolderRef}
          />
        </div>

        {checkCardData.data?.show_full_name_input && (
          <div className={styles.input}>
            <InputLabel
              inputRef={cardHolderRef}
              id="filled"
              autoComplete={'cc-name'}
              name="cardHolder"
              variant="filled"
              label={t('components:card_form.Держатель карты')}
              type={'text'}
              value={cardFormValue.cardHolder}
              onChange={handleChange}
              customError={errors?.cardHolder}
            />
          </div>
        )}
        {checkCardData.data?.show_phone_input && (
          <InputPhone
            className={styles.input}
            specialLabel={t('components:card_form.Телефон')}
            inputProps={{
              id: 'filled',
              name: 'phone_number',
            }}
            country="uz"
            value={cardFormValue.phone_number}
            onChange={handlePhoneChange}
            error={errors.phone_number}
          />
        )}
        {stepOneData?.use_email && (
          <div className={styles.input}>
            <InputLabel
              id="filled"
              name="email"
              label={t('components:card_form.Email для чека')}
              variant="filled"
              value={cardFormValue.email}
              onChange={handleChange}
              customError={errors?.email ?? ''}
            />
          </div>
        )}
      </form>

      <Button
        className={styles.button}
        form="cardForm"
        disabled={!checkCardData.data?.secret_key}
        // disabled={!Object.values(isFormValid).every((value) => value)}
      >
        {t('ui:buttons.Оплатить')} {invoiceSumToPay}
      </Button>
    </div>
  )
}
