import React, { useContext } from 'react'
import { StatusPaymentTypeVariants } from '../../../StatusPayment/StatusPayment.types'
import { PaymentService } from '../../../../config/payment_services/types'

interface IInvoiceContext {
  isRateButtonVisible: boolean
  setIsRateButtonVisible: (isRateButtonVisible: boolean) => void
  invoiceStatus: IInvoiceStatus
  setInvoiceStatus: (invoiceStatus: IInvoiceStatus) => void
  isRedirecting: boolean
  setIsRedirecting: (isRedirecting: boolean) => void
  isWaitingForPayment: boolean
  setIsWaitingForPayment: (isWaitingForPayment: boolean) => void
  servicesCount: number | undefined
  setServicesCount: (servicesCount: number | undefined) => void
  invoiceAmount: IInvoiceAmount
  setInvoiceAmount: (
    invoiceAmount: (prev: IInvoiceAmount) => { amount: any; currency: any }
  ) => void
  service: PaymentService
}

export const InvoiceContext = React.createContext<IInvoiceContext>({
  isRateButtonVisible: false,
  setIsRateButtonVisible: (_isRateButtonVisible) => null,
  invoiceStatus: {} as IInvoiceStatus,
  setInvoiceStatus: (_invoiceStatus) => null,
  isRedirecting: false,
  setIsRedirecting: (_isRateButtonVisible) => null,
  isWaitingForPayment: false,
  setIsWaitingForPayment: (_isRateButtonVisible) => null,
  servicesCount: undefined,
  setServicesCount: (_servicesCount) => null,
  invoiceAmount: {} as IInvoiceAmount,
  setInvoiceAmount: (_invoiceAmount) => null,
  service: {} as PaymentService,
})

export const useInvoice = () => {
  return useContext(InvoiceContext)
}

export interface IInvoiceStatus {
  type?: StatusPaymentTypeVariants
  text?: string
  title?: string
  action?: string // TODO посмотреть какая там всё-таки типизация должна быть
  visible: boolean
  options: {
    type: StatusPaymentTypeVariants
    text: string
    title: string
    action: string
  }
}

export interface IInvoiceAmount {
  amount: string
  currency: string
}
