import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './PaymentOffer.module.scss'
import { PciSecure } from './PciSecure/PciSecure'
import PaymentSystemsImage from '../../assets/images/icons/payment_systems.png'

export const PaymentOffer = () => {
  const { t } = useTranslation(['components'])

  return (
    <div className={styles.container}>
      <p className={styles.offerText}>
        {t('components:payment_offer.Нажимая кнопку «Оплатить», вы даете')}{' '}
        <a href="https://quickpay.uz/personal_data.pdf" target="_blank" rel="noreferrer noopener">
          {t('components:payment_offer.Согласие на обработку персональных данных')}.
        </a>
      </p>

      <img className={styles.paymentSystems} src={PaymentSystemsImage} alt="payment systems" />

      <PciSecure />
    </div>
  )
}
