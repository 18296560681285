import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import TRANSLATION_COMPONENTS_RU from './translations/ru-RU/components.json'
import TRANSLATION_UI_RU from './translations/ru-RU/ui.json'
import TRANSLATION_COMPONENTS_EN from './translations/en-US/components.json'
import TRANSLATION_UI_EN from './translations/en-US/ui.json'
import TRANSLATION_COMPONENTS_UZ from './translations/uz-UZ/components.json'
import TRANSLATION_UI_UZ from './translations/uz-UZ/ui.json'

import moment from 'moment'
import 'moment/min/locales'

//Документация по конфигу https://www.i18next.com/overview/configuration-options

const MOMENT_LANGS: Record<string, string> = {
  uz: 'uz-latn',
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', function (lng) {
    // При смене языка закидываем язык в куки и меняем moment на этот язык
    // Cookies.set('i18next', lng)
    moment.locale(MOMENT_LANGS[lng] ?? lng)
  })
  //@ts-ignore
  .init({
    fallbackLng: 'en',
    ns: ['components'],
    // ns: ['sidebar', 'ui', 'create_merchant', 'components', 'common', 'settings', 'auth'],
    defaultNS: 'components',
    nsSeparator: ':',
    debug: true,
    pluralSeparator: '--',
    supportedLngs: ['ru', 'en', 'uz'],
    resources: {
      en: {
        components: TRANSLATION_COMPONENTS_EN,
        ui: TRANSLATION_UI_EN,
      },
      ru: {
        ui: TRANSLATION_UI_RU,
        components: TRANSLATION_COMPONENTS_RU,
      },
      uz: {
        ui: TRANSLATION_UI_UZ,
        components: TRANSLATION_COMPONENTS_UZ,
      },
      //   es: {
      //     sidebar: TRANSLATION_SIDEBAR_ES,
      //     ui: TRANSLATION_UI_ES,
      //     create_merchant: TRANSLATION_CREATE_MERCHANT_ES,
      //     components: TRANSLATION_COMPONENTS_ES,
      //     common: TRANSLATION_COMMON_ES,
      //     settings: TRANSLATION_SETTINGS_ES,
      //     auth: TRANSLATION_AUTH_ES,
      //   },
    },
    // detection: options,

    detection: {
      order: ['cookie', 'navigator', 'localStorage'],
      cache: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
