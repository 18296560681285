type getCurrencySign = '₽' | '$' | '€' | '₸' | '₴' | 'UZS'

export const getCurrencySign = (currency: string): getCurrencySign => {
  switch (currency) {
    case 'RUB':
      return '₽'
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    case 'KZT':
      return '₸'
    case 'UAH':
      return '₴'
    case 'UZS':
      return 'UZS'
    default:
      return '₽'
  }
}
