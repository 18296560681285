import React from 'react'
import cn from 'classnames'
import styles from './ButtonWidget.module.scss'
import { ReactComponent as IconEnot } from 'assets/images/icons/enot-logo-mini-widget.svg'
import { IParamsObj } from '../Widget'

const getButtonProps: {
  [key: string]: { width?: number; padding?: string; fontSize?: string; background?: string }
} = {
  large: {
    width: 240,
    padding: '16px 20px',
    fontSize: '15px',
  },
  medium: {
    width: 200,
    padding: '14px 24.5px',
    fontSize: '15px',
  },
  small: {
    width: 150,
    padding: '8px 13px',
    fontSize: '12px',
  },
}

export const ButtonWidget = ({
  params,
  handleSubmit,
}: {
  params: IParamsObj
  handleSubmit: () => void
}) => {
  return (
    <button
      className={cn(
        styles.buttonPreview,
        styles[params.size ?? 'large'],
        styles[params.color ?? 'white']
      )}
      style={{
        width: getButtonProps[params.size ?? 'large']['width'],
        fontSize: getButtonProps[params.size ?? 'large']['fontSize'],
        padding: getButtonProps[params.size ?? 'large']['padding'],
      }}
      onClick={handleSubmit}
    >
      <span className={styles.image}>
        <IconEnot />
      </span>
      <span className={styles.text}>ENOT Pay</span>
    </button>
  )
}
