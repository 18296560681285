import React, { ReactNode, useEffect, useRef, useState } from 'react'
import './DropdownLanguage.scss'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import Cookies from 'js-cookie'
import { ReactComponent as IconArrow } from 'assets/images/ui/icon-dropdown-arrow.svg'
import { ReactComponent as IconUsFlag } from 'assets/images/icons/icon-us.svg'
import { ReactComponent as IconRuFlag } from 'assets/images/icons/icon-ru.svg'
import { ReactComponent as IconUzFlag } from 'assets/images/icons/icon-uz.svg'
import { changeGlobalLanguage } from '../../../../../../helpers/changeGlobalLanguage/changeGlobalLanguage'
import { useQueryClient } from '@tanstack/react-query'
import { getLanguageFullName } from '../../../../../../helpers/getLanguageFullName/getLanguageFullName'
import { useLocation } from 'react-router-dom'

const variants = {
  open: { height: 'auto' },
  closed: { height: 0 },
}

const FLAGS: Record<string, ReactNode> = {
  en: <IconUsFlag />,
  ru: <IconRuFlag />,
  uz: <IconUzFlag />,
}

export const DropdownLanguage = () => {
  const queryClient = useQueryClient()
  const ref = useRef<HTMLDivElement>(null)
  const {
    i18n: { changeLanguage, language, services },
  } = useTranslation()
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)

  const locale = queryParams.get('locale')

  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    if (locale) {
      const langStrict = localStorage.getItem('_langStrict')

      if (!langStrict) {
        changeLanguage(locale)
        Cookies.set('i18next', locale)
      }
    }
  }, [locale])

  const handleSelect = (language: string) => {
    changeLanguage(language)
    setIsActive(!isActive)
    queryClient.invalidateQueries(['getInvoiceInfo', 'getInvoiceReceipt'])
    changeGlobalLanguage(language)

    Cookies.remove('usedesk_messenger_token')
    Cookies.remove('usedesk_messenger_callback_chat_id')
  }

  const useOutsideAlerter = (ref: any) => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }

  useOutsideAlerter(ref)

  return (
    <div className="dropdown__language" ref={ref}>
      <button
        className={`dropdown-btn ${isActive ? 'active' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="dropdown__flag">{FLAGS[language]}</div>
        {getLanguageFullName(language)}
        <div className={`dropdown__arrow ${isActive ? 'active' : ''}`}>
          <IconArrow />
        </div>
      </button>

      <motion.div
        className={`dropdown__content ${
          window.innerHeight - Number(ref?.current?.offsetTop) > 150 ? 'top' : 'bottom'
        }`}
        animate={isActive ? 'open' : 'closed'}
        variants={variants}
        // custom={ref?.curent?.getBoundingClientRect().bottom}
        initial={false}
      >
        {Object.keys(services.resourceStore.data)?.map((lang) => (
          <div className="dropdown__item" onClick={() => handleSelect(lang)} key={lang}>
            <span className="item__text">
              <span className="item__text">{getLanguageFullName(lang)}</span>
            </span>
          </div>
        ))}
        {/* <div className="dropdown__item" onClick={handleSelect}>
          <span className="item__text">Русский</span>
        </div> */}
      </motion.div>
    </div>
  )
}
