import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SidebarStatusEnum,
  useSidebar,
} from '../../components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { PaymentLoader } from '../../components/PaymentLoader/PaymentLoader'
import { StatusPayment } from '../../components/StatusPayment/StatusPayment'
import { getCurrencySign } from '../../helpers/getCurrencySign'
import { getDomainName } from '../../helpers/getDomainName/getDomainName'
import { InvoiceService } from '../../services/invoice/invoice.service'
import { InvoiceStatusEnum } from '../../services/invoice/invoice.service.types'
import { Button } from '../../ui-lava/Button/Button'
import { InvoiceLayoutSkeleton } from '../Invoice/InvoiceLayoutSkeleton/InvoiceLayoutSkeleton'
import { CreatedStatus } from './CreatedStatus/CreatedStatus'
import { PaymentError } from './PaymentError/PaymentError'
import styles from './StatusPage.module.scss'
import { useInvoice } from '../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { AutoRedirect } from './AutoRedirect/AutoRedirect'

export const StatusPage = () => {
  const { setSidebarStatus } = useSidebar()
  const { setIsRateButtonVisible } = useInvoice()
  const { t } = useTranslation(['components', 'ui'])
  //Стейт для флага о том, что не нужно больше запрашивать статус
  const [isInvoiceFinal, setIsInvoiceFinal] = useState<boolean>(false)

  const [errorPaymentMessage, setErrorPaymentMessage] = useState<string | undefined>(undefined)

  const { invoiceId } = useParams()
  const navigate = useNavigate()
  //ЗАПРОС НА ИНФО, ЕСЛИ ТАРИФ 1 - ЗНАЧИТ ПО НЕМУ ОЖИДАЕТСЯ ОПЛАТА.
  //  (НЕ ОБЯЗАТЕЛЬНО, ЧТО ЮЗЕР ДЕЙСТВИТЕЛЬО ПЫТАЛСЯ ОПЛАТИТЬ)
  //ЕСТЬ БАГ, ПРИ КОТОРОМ ЮЗЕР (В СЛУЧАЕ ЕСЛИ У НЕГО 1 ТАРИФ), МОЖЕТ САМ ПЕРЕЙТИ НА /status
  const {
    data: data_getInvoiceInfo,
    isLoading: isLoading_getInvoiceInfo,
    isError: isError_getInvoiceInfo,
  } = useQuery(['getInvoiceInfo'], () => InvoiceService.getInvoiceInfo(invoiceId ?? ''), {
    onSuccess: (data) => {
      const status = data?.data?.data?.status
      if (status === InvoiceStatusEnum.SUCCESS || status === InvoiceStatusEnum.FAIL) {
        setIsRateButtonVisible(false)
      }

      setIsInvoiceFinal(
        status === InvoiceStatusEnum.SUCCESS ||
          status === InvoiceStatusEnum.EXPIRED ||
          status === InvoiceStatusEnum.FAIL
      )

      if (!data?.data?.data?.rating) {
        setIsRateButtonVisible(true)
      }
    },

    staleTime: 5000,
  })
  const {
    data: data_getInvoiceStatus,
    isLoading: isLoading_getInvoiceStatus,
    isError: isError_getInvoiceStatus,
  } = useQuery(['getInvoiceStatus'], () => InvoiceService.getInvoiceStatus(invoiceId ?? ''), {
    onSuccess: ({ data }) => {
      if (data?.data?.error_message) {
        setErrorPaymentMessage(data?.data?.error_message)
      }

      if (
        data?.data?.status === InvoiceStatusEnum.SUCCESS ||
        data?.data?.status === InvoiceStatusEnum.FAIL
      ) {
        setIsRateButtonVisible(false)
      }

      setIsInvoiceFinal(data?.data?.status === InvoiceStatusEnum.SUCCESS)
    },
    enabled: !errorPaymentMessage && !isInvoiceFinal,
    refetchInterval: 3000,
    staleTime: 2000,
  })

  const { data: receiptData, isLoading: isLoadingReceiptData } = useQuery(
    ['getInvoiceReceipt'],
    () => InvoiceService.getInvoiceReceipt(invoiceId ?? ''),
    {
      enabled: isInvoiceFinal,
      staleTime: 5000,
      retry: false,
    }
  )

  const actionHandler = () => {
    if (!receiptData?.data?.data) return
    return window.open(receiptData?.data?.data?.cheque_url || '', '_blank')
  }

  // ТУТ ЗАПРОС НА СТАТУС, КОТОРОГО ЕЩЕ НЕТ

  const getStatusPageContent = () => {
    //Все статусы проработать
    switch (data_getInvoiceStatus?.data?.data?.status) {
      case InvoiceStatusEnum.CREATED:
        return (
          <CreatedStatus
            serviceName={
              data_getInvoiceInfo?.data?.data?.tariff_group?.[0]?.tariffs?.[0]?.service ?? ''
            }
          />
        )
      case InvoiceStatusEnum.SUCCESS:
        return (
          <AutoRedirect url={data_getInvoiceInfo?.data?.data?.success_url}>
            <StatusPayment
              title={t('components:status_page.Успешная оплата')}
              type="success"
              text={
                <>
                  {t('components:status_page.Вы успешно оплатили заказ на сумму')}{' '}
                  <span className={styles.amount}>
                    {data_getInvoiceInfo?.data?.data?.amount}{' '}
                    {getCurrencySign(data_getInvoiceInfo?.data?.data?.currency ?? '')}
                  </span>
                  <br />
                  {t('components:status_page.в магазине')}{' '}
                  {getDomainName(data_getInvoiceInfo?.data?.data?.domain)}
                </>
              }
              action={
                !!receiptData?.data?.data && (
                  <Button onClick={actionHandler}>{t('ui:buttons.Получить чек')}</Button>
                )
              }
            />
          </AutoRedirect>
        )

      case InvoiceStatusEnum.FAIL:
        return (
          <AutoRedirect url={data_getInvoiceInfo?.data?.data?.fail_url}>
            <StatusPayment
              type="error"
              title={t('components:status_page.Произошла ошибка')}
              text={
                data_getInvoiceStatus?.data?.data?.error_message ??
                t('components:status_payment.Попробуйте снова, либо обратитесь в поддержку')
              }
            />
          </AutoRedirect>
        )

      case InvoiceStatusEnum.EXPIRED:
        return (
          <StatusPayment
            type="error"
            title={t('components:status_page.Произошла ошибка')}
            text={t('components:status_page.Время действия счета истекло')}
          />
        )

      case InvoiceStatusEnum.REFUND:
        return <InvoiceLayoutSkeleton />

      default:
        return <PaymentLoader text={t('components:status_page.Обрабатываем ваш платеж')} />
    }
  }

  useEffect(() => {
    //Потестить - улучшить
    const countService = data_getInvoiceInfo?.data?.data?.tariff_group?.reduce(
      (totalLength, item) => {
        let tariffsLength = 0
        if (item?.tariffs?.length) tariffsLength = item.tariffs.length
        return totalLength + tariffsLength
      },
      0
    )
    const firstService = data_getInvoiceInfo?.data?.data?.tariff_group?.[0]?.tariffs?.[0]

    // if (firstService && !firstService.is_final && countService > 1) {
    if (firstService && !firstService.is_final && Number(countService) > 1) {
      navigate(
        `/${invoiceId}/${data_getInvoiceInfo?.data?.data?.tariff_group?.[0]?.tariffs?.[0].service}`
      )
      setSidebarStatus(SidebarStatusEnum.COLLAPSED)
    } else {
      setSidebarStatus(SidebarStatusEnum.CLOSED)
      navigate(``)
    }
  }, [data_getInvoiceInfo?.data?.data?.tariff_group])

  if (isLoading_getInvoiceInfo || isLoading_getInvoiceStatus) {
    return <InvoiceLayoutSkeleton />
  }

  if (errorPaymentMessage) {
    return (
      <div className={styles.container}>
        <AutoRedirect url={data_getInvoiceInfo?.data?.data?.fail_url}>
          <PaymentError
            error={errorPaymentMessage}
            serviceName={
              data_getInvoiceInfo?.data?.data?.tariff_group?.[0]?.tariffs?.[0]?.service ?? ''
            }
          />
        </AutoRedirect>
      </div>
    )
  }

  return <div className={styles.container}>{getStatusPageContent()}</div>
}
