import React, { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useMethodsContext,
} from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { MethodsContentSkeleton } from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContentSkeleton/MethodsContentSkeleton'
import { PaymentOffer } from '../../../../components/PaymentOffer/PaymentOffer'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { AvailableServicesType } from '../../../../helpers/types/availableMethods.type'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import {
  IPayQiwiFormPayload,
  IPayQiwiUrlPayload,
} from '../../../../services/invoice/invoice.service.types'
import { QiwiForm } from './QiwiForm/QiwiForm'
import { QiwiUrlForm } from './QiwiUrlForm/QiwiUrlForm'
import {useInvoice} from "../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext";
import { IResponseError } from '../../../../types/IResponse'

export const Qiwi = () => {
  const { t } = useTranslation(['components'])
  const { setIsRedirecting } = useInvoice()
  const { stepOneData } = useMethodsContext()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    mutate: payQiwiUrl,
    isLoading: isLoading_payQiwiUrl,
    isError: isError_payQiwiUrl,
    error: error_payQiwiUrl,
  } = useMutation(['payQiwiUrl'], (data: IPayQiwiUrlPayload) => InvoiceService.payQiwiUrl(data), {
    onSuccess: (data) => {
      if (data?.data?.data?.url && data?.data?.data?.is_redirect) {
        window.location.href = data.data.data.url
        setIsRedirecting(true)
      }
    },
    onError: (_error: IResponseError) => null // Не удалять (нужно для типизации ошибки)
    // onError: (data) => {
    //   // setInvoiceStatus({
    //   //   visible: true,
    //   //   options: {
    //   //     type: 'error',
    //   //     text: 'error url card',
    //   //     title: 'error url card',
    //   //   },
    //   // })
    // },
  })

  const {
    mutate: payQiwiForm,
    isLoading: isLoading_payQiwiForm,
    isError: isError_payQiwiForm,
    error: error_payQiwiForm,
  } = useMutation(
    ['payQiwiForm'],
    (data: IPayQiwiFormPayload) => InvoiceService.payQiwiForm(data),
    {
      onSuccess: (data) => {
        // setMethodsStep(MethodsStepEnum.FINAL)
        if (data?.data?.data?.url && data?.data?.data?.is_redirect) {
          window.location.href = data?.data?.data?.url
          setIsRedirecting(true)
        }
      },
      onError: (_error: IResponseError) => null // Не удалять (нужно для типизации ошибки)
      // onError: (data) => {
      //   // setInvoiceStatus({
      //   //   visible: true,
      //   //   options: {
      //   //     type: 'error',
      //   //     text: 'error url card',
      //   //     title: 'error url card',
      //   //   },
      //   // })
      // },
    }
  )

  useEffect(() => {
    if (stepOneData?.use_email) {
      //еще доп проверка на qiwi url form
      //делаем запрос на степ-2
      //авторедирект по урлу из степ-2
    }
  }, [stepOneData])

  const onSubmitQiwiService = ({phone} : {phone?: string}) => {
    if (stepOneData?.service_type === AvailableServicesType.QIWI_FORM) {
      payQiwiForm({
        secretKey: stepOneData?.signature ?? '',
        phone: Number(phone) || 0,
      })
    } else {
      payQiwiUrl({
        secretKey: stepOneData?.signature ?? '',
      })
    }
  }

  const getQiwiContent = () => {
    switch (stepOneData?.service_type) {
      case AvailableServicesType.QIWI_FORM:
        return <QiwiForm onSubmitForm={onSubmitQiwiService} />
      case AvailableServicesType.QIWI_URL_FORM:
        return <QiwiUrlForm onSubmitForm={onSubmitQiwiService} />
      default:
        return <MethodsContentSkeleton />
    }
  }

  if (isLoading_payQiwiForm || isLoading_payQiwiUrl) {
    return <MethodsContentSkeleton />
  }

  if (isError_payQiwiForm || isError_payQiwiUrl) {
    return (
      <StatusPayment
        // action={<Button onClick={() => payCardFormMutate()}>Попробовать еще раз</Button>}
        title={t('components:status_payment.Произошла ошибка')}
        text={
          error_payQiwiForm?.response?.data?.error ??
          error_payQiwiUrl?.response?.data?.error ??
          t(
            'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку'
          )
        }
      />
    )
  }

  return (
    <div>
      {getQiwiContent()}
      <PaymentOffer />
    </div>
  )
}
