import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button } from '../../../../../ui-lava/Button/Button'
import { CryptoStepsEnum } from '../CryptoMethods.types'
import styles from './CryptoDescription.module.scss'
import { AvailableMethodsEnum } from '../../../../../helpers/types/availableMethods.type'

export const walletNames: Partial<Record<AvailableMethodsEnum, string>> = {
  bitcoin: 'BTC',
  litecoin: 'LTC',
  ethereum: 'ETH',
  trx: 'TRX',
  dash: 'DASH',
  ton: 'TON',
  usdt_trc20: 'USDT TRC20',
  usdt_erc20: 'USDT ERC20',
}

interface Props {
  setStep: Dispatch<SetStateAction<CryptoStepsEnum>>
}

export const CryptoDescription = ({ setStep }: Props) => {
  const { t } = useTranslation(['components', 'ui'])

  const { methodName } = useParams()

  const getWalletName = () => {
    return walletNames ? [methodName] : ''
  }
  const clickHandler = () => setStep(CryptoStepsEnum.PAYMENT)

  return (
    <div>
      <p className={styles.description}>
        {t('components:crypto_methods.Нажав кнопку показать реквизиты', {
          walletName: getWalletName(),
        })}
      </p>
      <Button onClick={clickHandler}>{t('ui:buttons.Показать реквизиты')}</Button>
    </div>
  )
}
