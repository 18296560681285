import React, { useEffect, useMemo, useState } from 'react'
import { IInvoiceAmount, IInvoiceStatus, InvoiceContext } from './InvoiceContext'
import { useMatch } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { IGetInvoiceInfoResponse } from '../../../../services/invoice/invoice.service.types'
import { paymentServices } from '../../../../config/payment_services/paymentServices'
import { PaymentServices } from '../../../../config/payment_services/types'

interface IInvoiceProvider {
  children?: React.ReactNode
}

const getServiceFromUrl = () =>
  Object.keys(paymentServices).find((key) => window.location.origin.match(key)) as PaymentServices

export const InvoiceProvider = ({ children }: IInvoiceProvider) => {
  const isNotChoicePayment = useMatch('/:id')
  const queryClient = useQueryClient()
  const invoice = queryClient.getQueryData<{ data: IGetInvoiceInfoResponse }>(['getInvoiceInfo'])

  const [invoiceStatus, setInvoiceStatus] = useState<IInvoiceStatus>({
    visible: false,
    options: {
      type: 'loading',
      text: '',
      title: '',
      action: '',
    },
  })
  const [servicesCount, setServicesCount] = useState<number | undefined>(undefined)
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const [isWaitingForPayment, setIsWaitingForPayment] = useState<boolean>(false)
  const [invoiceAmount, setInvoiceAmount] = useState<IInvoiceAmount>({
    amount: '',
    currency: '',
  })
  const [isRateButtonVisible, setIsRateButtonVisible] = useState<boolean>(false)

  const serviceName = useMemo(() => getServiceFromUrl() ?? 'quickpay', [])

  useEffect(() => {
    const amount = String(invoice?.data?.data?.amount)

    if (isNotChoicePayment && amount) setInvoiceAmount((prev) => ({ ...prev, amount }))
  }, [isNotChoicePayment])

  const service = useMemo(() => paymentServices[serviceName], [serviceName])

  return (
    <InvoiceContext.Provider
      value={{
        isRateButtonVisible,
        setIsRateButtonVisible,
        invoiceStatus,
        setInvoiceStatus,
        isRedirecting,
        setIsRedirecting,
        isWaitingForPayment,
        setIsWaitingForPayment,
        servicesCount,
        setServicesCount,
        invoiceAmount,
        setInvoiceAmount,
        service,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
}
