import axios from 'axios'
import i18next from 'i18next'

const hostname = window.location.hostname

export const api = axios.create({
  baseURL: 'https://backend.quickpay.uz/api/v1/',

  timeout: 25000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
})

api.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers['locale'] = i18next.language === 'uz' ? 'en' : i18next.language
      return config
    }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)
