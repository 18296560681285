import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { CryptoMethodItem } from '../../../../components/CryptoMethodItem/CryptoMethodItem'
import { useMethodsContext } from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import {
  SidebarStatusEnum,
  useSidebar,
} from '../../../../components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import { CryptoSkeleton } from '../../CryptoSkeleton/CryptoSkeleton'
import { CryptoDescription, walletNames } from './CryptoDescription/CryptoDescription'
import { CryptoStepsEnum } from './CryptoMethods.types'
import { useInvoice } from '../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { IResponseError } from '../../../../types/IResponse'
import { AvailableMethodsEnum } from '../../../../helpers/types/availableMethods.type'

const queriesUrl: Record<string, string> = {
  bitcoin: 'pay-btc-qr',
  litecoin: 'pay-ltc-qr',
  ethereum: 'pay-eth-qr',
  trx: 'pay-trx-qr',
  dash: 'pay-dash-qr',
  ton: 'pay-ton-qr',
  usdt_trc20: 'pay-usdt-trc20-qr',
  usdt_erc20: 'pay-usdt-erc20-qr',
}

interface LocationState {
  service: string
}

type PageParams = Record<'methodName', AvailableMethodsEnum>

export const CryptoMethods = () => {
  const { methodName } = useParams<PageParams>()
  const { t } = useTranslation(['components'])
  const [cryptoSteps, setCryptoSteps] = useState<CryptoStepsEnum>(CryptoStepsEnum.DESCRIPTION)
  const { setSidebarStatus } = useSidebar()
  const { setIsWaitingForPayment } = useInvoice()
  const { stepOneData, email } = useMethodsContext()
  const { state } = useLocation()
  const queryClient = useQueryClient()
  const locationState = state as LocationState

  const getQueryUrl = () => {
    return queriesUrl[methodName ?? '']
  }

  const {
    data: data_payCrypto,
    isLoading: isLoading_payCrypto,
    isError: isError_payCrypto,
    error: error_payCrypto,
    mutate: mutate_payCrypto,
  } = useMutation(
    ['payCrypto'],
    () =>
      InvoiceService.payCrypto(getQueryUrl(), {
        secretKey: stepOneData?.signature ?? '',
        email: email === '' ? undefined : email,
      }),
    {
      onSuccess: () => {
        setSidebarStatus(SidebarStatusEnum.CLOSED)
        setCryptoSteps(CryptoStepsEnum.FINAL)
        setIsWaitingForPayment(true)
        queryClient.invalidateQueries(['getInvoiceInfo'])
      },
      onError: (_error: IResponseError) => {
        setCryptoSteps(CryptoStepsEnum.DESCRIPTION)
      },
    }
  )

  useEffect(() => {
    //Проверяем, если в степ-1 был type qr_address - переходим к финальному статусу, где отображаем qr
    if (stepOneData?.type === 'qr_address') {
      setCryptoSteps(CryptoStepsEnum.FINAL)
      setIsWaitingForPayment(true)
    }

    //запрос делаем только в случае если нажали на "показать реквизиты"
    locationState.service &&
      cryptoSteps === CryptoStepsEnum.PAYMENT &&
      stepOneData?.type !== 'qr_address' &&
      mutate_payCrypto()
  }, [locationState, cryptoSteps, stepOneData?.type])

  const pay_Crypto = data_payCrypto?.data?.data

  if (isLoading_payCrypto) {
    return <CryptoSkeleton />
  }

  if (isError_payCrypto) {
    return (
      <StatusPayment
        title={t('components:status_payment.Произошла ошибка')}
        type="error"
        text={
          error_payCrypto?.response?.data?.error ??
          t(
            'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку'
          )
        }
      />
    )
  }

  switch (cryptoSteps) {
    case CryptoStepsEnum.DESCRIPTION:
      return <CryptoDescription setStep={setCryptoSteps} />
    case CryptoStepsEnum.FINAL:
      return (
        <CryptoMethodItem
          address={pay_Crypto?.address ? String(pay_Crypto.address) : String(stepOneData?.address)}
          amount={
            pay_Crypto?.currency_amount
              ? pay_Crypto.currency_amount
              : Number(stepOneData?.currency_amount)
          }
          currency={walletNames[methodName as AvailableMethodsEnum] ?? ''}
          qr={pay_Crypto?.qr_code ?? stepOneData?.qr_code}
        />
      )
    default:
      return <CryptoSkeleton />
  }
}
