import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useMethodsContext,
} from '../../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { Button } from '../../../../../ui-lava/Button/Button'

export const QiwiUrlForm = ({ onSubmitForm }: { onSubmitForm: any }) => {
  const { stepOneData } = useMethodsContext()
  const { t } = useTranslation(['ui'])

  useEffect(() => {
    stepOneData?.use_email && onSubmitForm()
  }, [])

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault()
          onSubmitForm()
        }}
      >
        {t('ui:buttons.Оплатить')}
      </Button>
    </>
  )
}
