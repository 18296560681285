import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import React from 'react'

import ru from 'react-phone-input-2/lang/ru.json'

import 'react-phone-input-2/lib/material.css'
import styles from './InputPhone.module.scss'

export interface IInputPhoneProps extends PhoneInputProps {
  className?: string
  onChange?: (value: string) => void
  error?: string
}

const VARIANTS = {
  hide: { opacity: 0.1, height: 0, marginTop: 0 },
  show: { opacity: 1, height: 'auto', marginTop: 4 },
}
const TRANSITION = { duration: 0.2 }

export const InputPhone = ({ error, className, inputProps, ...props }: IInputPhoneProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <div className={cn(className, styles.containerInput)}>
      <PhoneInput
        inputClass={cn(styles.inputField, {
          [styles.error]: error !== '',
        })}
        containerClass={cn(styles.phoneInputContainer, {
          empty: (props.value ?? '').length === 0,
        })}
        localization={language === 'ru' ? ru : undefined}
        dropdownClass={styles.phoneInputDropdown}
        enableAreaCodeStretch
        autoFormat={true}
        inputProps={{
          placeholder: '',
          ...inputProps,
        }}
        onMount={(_, country) => {
          if ('dialCode' in country) props.onChange?.(country.dialCode)
        }}
        {...props}
      />

      <AnimatePresence>
        {error && (
          <motion.div
            transition={TRANSITION}
            variants={VARIANTS}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <div className={styles.error}>{error}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
