import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useSidebar } from '../SidebarContext/SidebarContext'
import styles from './SidebarItem.module.scss'
import { ISidebarItem } from './SidebarItem.types'
import cn from 'classnames'
import { useMediaQuery } from '@mui/material'

export const SidebarItem = ({ item, isExpanded }: ISidebarItem) => {
  const { openSidebar, setIsServiceSelected } = useSidebar()
  const isTabletS = useMediaQuery('(max-width: 576px)')
  const { methodName } = useParams()

  return (
    <button
      className={cn(styles.item, {
        [styles.active]: methodName === item.service,
        [styles.isExpanded]: isExpanded,
      })}
      onClick={openSidebar}
    >
      <NavLink
        to={`${item.service}`}
        className={styles.link}
        replace={!isTabletS}
        state={{ service: item?.service }}
        //Нужно для того чтоб проверить - поменял ли пользователь метод
        onClick={() => {
          setIsServiceSelected(true)
        }}
      >
        <img
          className={styles.image}
          src={`/img/icon-${item.service}.svg`}
          alt={`icon-${item.service}`}
        />

        <span className={styles.name}>{item.label}</span>
      </NavLink>
    </button>
  )
}
