import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useCountdown } from 'hooks/useCountdown'

import styles from './AutoRedirect.module.scss'

interface AutoRedirectProps {
  url?: string
  children: ReactNode
}

export const AutoRedirect: FC<AutoRedirectProps> = ({ url, children }) => {
  const { t } = useTranslation(['components'])

  const redirect = () => {
    if (url) window.location.href = url
  }
  const { timeLeft } = useCountdown(5, redirect)

  return (
    <div>
      {children}
      {url && (
        <span className={styles.countdown}>
          {t('components:status_page.Вы будете перенаправлены обратно', { seconds: timeLeft ?? 0 })}
        </span>
      )}
    </div>
  )
}
