import cn from 'classnames'
import React, { ButtonHTMLAttributes, FC, ReactNode, useRef } from 'react'
import { useEventListener } from '../../hooks/useEventListener'
import { ReactComponent as IconCardActive } from '../../assets/images/icons/icon-card.svg'

import styles from './Button.module.scss'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'primary' | 'secondary' | 'tertirary' | 'outline'
  width?: string
  children?: ReactNode
  size?: 'small' | 'medium' | 'large'
  fetching?: boolean
}

export const Button: FC<IButtonProps> = ({
  children,
  width = '100%',
  size = 'large',
  appearance = 'primary',
  className,
  type,
  disabled,
  fetching = false,
  ...props
}) => {
  useEventListener('keydown', (event) => {
    if (type === 'submit' && !disabled) {
      if (event.key === 'Enter') {
        buttonRef.current?.click()
      }
    }
  })

  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <button
      ref={buttonRef}
      className={cn(className, styles.btn, {
        [styles.primary]: appearance === 'primary',
        [styles.secondary]: appearance === 'secondary',
        [styles.tertirary]: appearance === 'tertirary',
        [styles.outline]: appearance === 'outline',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.fetching]: fetching,
      })}
      style={{ width }}
      disabled={disabled || fetching}
      {...props}
    >
      {fetching && (
        <div className={styles.loader}>
          {disabled ||
          appearance === 'secondary' ||
          appearance === 'tertirary' ||
          appearance === 'outline' ? (
            <IconCardActive />
          ) : (
            <IconCardActive />
          )}
        </div>
      )}

      <span className={styles.children}>{children}</span>
    </button>
  )
}
