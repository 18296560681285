export const availableQiwiCountries = [
  'ru',
  'by',
  'az',
  'in',
  'kz',
  'gb',
  'ge',
  'lt',
  'tj',
  'tw',
  'uz',
  'pa',
  'am',
  'lv',
  'tr',
  'md',
  'vn',
  'il',
  'ee',
  'kr',
  'kg',
]
