import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDebounce } from '../../../../hooks/useDebounce'
import { IGetInvoiceInfo, IServiceItem } from '../../../../services/invoice/invoice.service.types'
import { ReactComponent as IconSidebarBurger } from 'assets/images/ui/sidebar-burger.svg'
import './Sidebar.scss'
import { SidebarStatusEnum, useSidebar } from './SidebarContext/SidebarContext'
import { SidebarItem } from './SidebarItem/SidebarItem'
import { useInvoice } from '../InvoiceContext/InvoiceContext'
import cn from 'classnames'
import { useMediaQuery } from '@mui/material'

interface Props {
  data?: IGetInvoiceInfo
}

export const Sidebar = ({ data }: Props) => {
  const { methodName } = useParams()
  const { state } = useLocation()

  const isTabletS = useMediaQuery('(max-width: 576px)')
  const isLaptopL = useMediaQuery('(max-width: 1024px)')

  const { sidebarStatus, setSidebarStatus, isServiceSelected } = useSidebar()
  const debouncedSidebarStatus = useDebounce(sidebarStatus, 100)
  const { servicesCount } = useInvoice()

  const isExpanded = sidebarStatus === SidebarStatusEnum.EXPANDED

  const isCollapsed = sidebarStatus === SidebarStatusEnum.COLLAPSED

  const animateStatusMotion = (() => {
    switch (debouncedSidebarStatus) {
      case SidebarStatusEnum.CLOSED:
        return 'closed'
      case SidebarStatusEnum.COLLAPSED:
        return 'collapsed'
      case SidebarStatusEnum.EXPANDED:
        return 'expanded'
    }
  })()

  const variantsLabel = {
    visible: {
      opacity: 1,
    },
    invisible: {
      opacity: isTabletS ? 1 : 0,
    },
  }

  const initialStateMotion = (() => {
    if (!isLaptopL) {
      if (!state || servicesCount === 1 || !servicesCount) {
        return 'closed'
      } else {
        return 'collapsed'
      }
    }

    if (isLaptopL && !isTabletS) {
      if (!state || servicesCount === 1 || !servicesCount) {
        return 'closed'
      }
      return 'collapsed'
    }

    return 'opened'
  })()

  const handleClickBurger = () => {
    if (isExpanded) {
      setSidebarStatus(SidebarStatusEnum.COLLAPSED)
      return
    }

    if (isCollapsed) {
      setSidebarStatus(SidebarStatusEnum.EXPANDED)
      return
    }
  }

  const handleMouseEventSidebar = (status: SidebarStatusEnum) => () => {
    if (!isLaptopL && methodName && isServiceSelected) setSidebarStatus(status)
  }

  return (
    <AnimatePresence>
      <motion.div
        className="sidebar"
        key="sidebar"
        initial={initialStateMotion}
        animate={animateStatusMotion}
        exit="closed"
        variants={variantsSidebar}
        custom={isTabletS}
        transition={{ bounce: false, duration: 0.15 }}
      >
        <div
          className={cn('burger-wrapper', {
            ['active']: isExpanded,
          })}
        >
          <button
            className={cn('burger-button', {
              ['active']: isExpanded,
            })}
            onClick={handleClickBurger}
          >
            <IconSidebarBurger />
          </button>
        </div>

        <div className="invoice-method">
          <div
            className="invoice-method-container"
            onMouseEnter={handleMouseEventSidebar(SidebarStatusEnum.EXPANDED)}
            onMouseLeave={handleMouseEventSidebar(SidebarStatusEnum.COLLAPSED)}
          >
            <div className="invoice-method__items">
              <div className="items__list">
                {data?.tariff_group?.map((tariff_group, index) => (
                  <React.Fragment key={index}>
                    <AnimatePresence exitBeforeEnter>
                      {tariff_group?.group_name === 'group_card' && (
                        <motion.span
                          initial="invisible"
                          animate={isExpanded ? 'visible' : 'invisible'}
                          custom={isTabletS}
                          variants={variantsLabel}
                          className={`list__sidebar-title`}
                        >
                          {tariff_group?.group_label}
                        </motion.span>
                      )}
                    </AnimatePresence>
                    {tariff_group?.tariffs
                      ?.filter(({ service }) => service === 'card')
                      .map((service: IServiceItem) => (
                        <SidebarItem item={service} isExpanded={isExpanded} key={service.label} />
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const variantsSidebar = {
  expanded: {
    maxWidth: 308,
    opacity: 1,
    display: 'block',
  },
  collapsed: {
    maxWidth: 88,
    opacity: 1,
    display: 'block',
  },
  closed: {
    maxWidth: 0,
    opacity: 1,
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    opacity: 1,
    maxWidth: '100%',
    display: 'block',
  },
}
