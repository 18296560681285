import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentOffer } from '../../../../../components/PaymentOffer/PaymentOffer'
import { Button } from '../../../../../ui-lava/Button/Button'

export const SbpUrl = ({ onSubmitForm }: { onSubmitForm: any }) => {
  const { t } = useTranslation(['ui'])
  return (
    <>
      <Button onClick={() => onSubmitForm()}>{t('ui:buttons.Оплатить')}</Button>
    </>
  )
}
