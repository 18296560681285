import { Skeleton } from '@mui/material'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './HeaderSkeleton.module.scss'

export const HeaderSkeleton = () => {
  const isTabletS = useMediaQuery({
    query: '(max-width: 576px)',
  })
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Skeleton variant="text" width={138} height={22} className={styles.backButton} />
        <Skeleton variant="text" width={115} height={36} className={styles.backButton} />
      </div>

      <div className={styles.right}>
        {isTabletS ? (
          <Skeleton variant="rectangular" width={61} height={22} className={styles.details} />
        ) : (
          <Skeleton variant="rectangular" width={162} height={48} className={styles.details} />
        )}
      </div>
    </div>
  )
}
