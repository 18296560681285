import { api } from '../../config/api'
import {
  ICreateWidgetPayload,
  ICreateWidgetResponse,
  IGetConvertCurrency,
  IGetConvertCurrencyPayload,
  IGetInvoiceInfoResponse,
  IGetInvoiceStatusResponse,
  IGetMultiPerfectResponse,
  IGetStepOneResponse,
  IGetTdsResponse,
  IMultiYooMoneyPayload,
  IMultiYooMoneyResponse,
  IPayCardFormPayload,
  IPayCardFormResponse,
  IPayCardUrlPayload,
  ICheckCardParams,
  IPayCardUrlResponse,
  IPayCrypto,
  IPayCryptoResponse,
  IPayPerfect,
  IPayPerfectResponse,
  IPayQiwiFormPayload,
  IPayQiwiFormResponse,
  IPayQiwiUrlPayload,
  IPaySbpUrlPayload,
  IPaySbpUrlResponse,
  IPayYooMoneyPayload,
  IPayYooMoneyResponse,
  ISetEvaluationPayload,
  ISetEvaluationResponse,
  ICheckCardResponse,
  IGetInvoiceReceiptResponse,
  IGetInvoiceWithCheckout,
} from './invoice.service.types'

export const InvoiceService = {
  async getInvoiceInfo(invoiceId: string) {
    return api.get<IGetInvoiceInfoResponse>(`invoice/${invoiceId}/info`)
  },
  async getInvoiceStatus(invoiceId: string) {
    return api.get<IGetInvoiceStatusResponse>(`invoice/${invoiceId}/status`)
  },

  async getInvoiceReceipt(invoiceId: string) {
    return api.get<IGetInvoiceReceiptResponse>(`invoice/${invoiceId}/cheque`)
  },

  async getStepOne({ invoiceId, method }: { invoiceId: string; method: string }) {
    return api.get<IGetStepOneResponse>(`payment/step-one/${invoiceId}?method=${method}`)
  },

  //STEP ONE - card
  async payCardForm(data: IPayCardFormPayload) {
    return api.post<IPayCardFormResponse>('payment/pay-card-form', data)
  },
  async payCardUrl(data: IPayCardUrlPayload) {
    return api.post<IPayCardUrlResponse>('payment/pay-card-url', data)
  },

  async payCardMulti({ invoiceId }: { invoiceId: string }) {
    return api.get<IPayCardFormResponse>(`invoice/multi/check-payment-tds/${invoiceId}`)
  },

  async checkCard(data: ICheckCardParams) {
    return api.post<ICheckCardResponse>('invoice/multi/check-card', data)
  },

  //STEP ONE - qiwi
  async payQiwiUrl(data: IPayQiwiUrlPayload) {
    return api.post<IPayCardUrlResponse>('payment/pay-qiwi-url', data)
  },
  async payQiwiForm(data: IPayQiwiFormPayload) {
    return api.post<IPayQiwiFormResponse>('payment/pay-qiwi-form', data)
  },

  //STEP ONE - SBP
  async paySbpUrl(data: IPaySbpUrlPayload) {
    return api.post<IPaySbpUrlResponse>('payment/pay-sbp-url', data)
  },

  //CRYPTO STEP-ONE
  async payCrypto(url: string, data: IPayCrypto) {
    return api.post<IPayCryptoResponse>(`payment/${url}`, data)
  },

  //PERFECT
  async payPerfect(data: IPayPerfect) {
    return api.post<IPayPerfectResponse>(`payment/pay-perfect-money`, data)
  },

  //YOOMONEY
  async payYooMoney(data: IPayYooMoneyPayload) {
    return api.post<IPayYooMoneyResponse>('payment/pay-yoomoney', data)
  },

  async getMultiYooMoney(data: IMultiYooMoneyPayload) {
    return api.post<IMultiYooMoneyResponse>(`invoice/multi/yoomoney`, data)
  },

  //TDS
  async getTds(tdsId: string) {
    return api.get<IGetTdsResponse>(`invoice/tds/${tdsId}`)
  },

  //Perfect Money Tds
  async getMultiPerfect(perfectId: string) {
    return api.get<IGetMultiPerfectResponse>(`invoice/multi/perfect/${perfectId}`)
  },

  //CONVERT CURRENCY
  async getConvertCurrency(data: IGetConvertCurrencyPayload) {
    return api.post<IGetConvertCurrency>(`payment/step-one/convert-currency`, data)
  },

  async setEvaluation({ data, invoiceId }: { data: ISetEvaluationPayload; invoiceId: string }) {
    return api.post<ISetEvaluationResponse>(`invoice/${invoiceId}/evaluations`, data)
  },

  async createWidget(data: ICreateWidgetPayload) {
    return api.post<ICreateWidgetResponse>(`widget/${data.widget_id}/invoice/create`, {
      additional_data: data?.additional_data,
    })
  },

  async getCheckRedirect(data: any) {
    return api.get(`invoice/create-invoice-old${data}`)
  },

  async getInvoiceWithCheckout(data: IGetInvoiceWithCheckout) {
    return api.post('invoice/checkout', data)
  },
}
