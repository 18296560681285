import React from 'react'
import { IGetMultiPerfect } from '../../../../services/invoice/invoice.service.types'

export const PerfectConfirmationForm = ({
  formRef,
  formData,
}: {
  formRef: React.ForwardedRef<HTMLFormElement>
  formData: IGetMultiPerfect
}) => {
  return (
    <form method="post" ref={formRef} action={'https://perfectmoney.is/api/step1.asp'}>
      <input type="hidden" name="PAYEE_ACCOUNT" value={formData?.wallet} />
      <input type="hidden" name="PAYEE_NAME" value={formData?.form_name} />
      <input type="hidden" name="PAYMENT_ID" value={formData?.id} />
      <input type="hidden" name="PAYMENT_AMOUNT" value={formData?.amount} />
      <input type="hidden" name="PAYMENT_UNITS" value={formData?.currency} />
      <input type="hidden" name="STATUS_URL" value={formData?.status_url} />
      <input type="hidden" name="PAYMENT_URL" value={formData?.success_url} />
      <input type="hidden" name="PAYMENT_URL_METHOD" value="GET" />
      <input type="hidden" name="NOPAYMENT_URL" value={formData?.fail_url} />
      <input type="hidden" name="NOPAYMENT_URL_METHOD" value="GET" />
      <input type="hidden" name="SUGGESTED_MEMO" value={formData?.description} />
      <input type="submit" />
    </form>
  )
}
