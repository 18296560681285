import React, { useEffect } from 'react'
// import IconQrCode from 'assets/images/icons/icon-qr-code.png'
import { ReactComponent as IconCopy } from 'assets/images/icons/icon-copy.svg'
import styles from './CryptoMethodItem.module.scss'
import { ICryptoMethodItem } from './CryptoMethodItem.types'
import { useTranslation } from 'react-i18next'
import { useInvoice } from '../Layout/Invoice/InvoiceContext/InvoiceContext'
import { useQueryClient } from '@tanstack/react-query'
import { IGetInvoiceInfoResponse } from '../../services/invoice/invoice.service.types'
import { useNavigate, useParams } from 'react-router-dom'
import { AvailableMethodsEnum } from '../../helpers/types/availableMethods.type'
import { copyTextInBuffer } from '../../helpers/copyTextBuffer'
import { useCustomSnackbar } from '../../hooks/useCustomSnackbar'

export const CryptoMethodItem = ({ address, amount, currency, qr }: ICryptoMethodItem) => {
  const { t } = useTranslation(['component'])
  const { isWaitingForPayment } = useInvoice()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { methodName, invoiceId } = useParams<{
    methodName?: AvailableMethodsEnum
    invoiceId: string
  }>()
  const enqueueSnackbar = useCustomSnackbar()
  const copyTextHandler = (value: string) => {
    copyTextInBuffer(value)
    enqueueSnackbar(t(
      'components:crypto_methods.Код успешно скопирован',
    ), {
      variant: "success"
    })
  }

  useEffect(() => {
    // Проверка на выбранный криптокошелёк, чтобы редиректило при переходах "назад" в браузере
    if (isWaitingForPayment && queryClient.getQueryData(['getInvoiceInfo'])) {
      const invoice = queryClient.getQueryData<{ data: IGetInvoiceInfoResponse } | undefined>(['getInvoiceInfo'])
      const firstService = invoice?.data.data?.tariff_group?.[0]?.tariffs?.[0]
      if (firstService?.service !== methodName) {
        navigate(`/${invoiceId}/${firstService?.service}`)
      }
    }
  }, [isWaitingForPayment])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.itemContent}>
            <h3 className={styles.title}>{t('components:crypto_methods.Адрес кошелька')}</h3>
            <p className={styles.text}>
              {address}
            </p>
          </div>

          <button className={styles.action} onClick={() => copyTextHandler(address)}>
            <IconCopy />
          </button>
        </div>

        <div className={styles.item}>
          <div className={styles.itemContent}>
            <h3 className={styles.title}>{t('components:crypto_methods.Сумма')}</h3>
            <p className={styles.text}>
              <span>{amount}</span> {currency}
            </p>
          </div>

          <button className={styles.action} onClick={() => copyTextHandler(amount.toString())}>
            <IconCopy />
          </button>
        </div>

        <p className={styles.contentDescription}>
          {t(
            'components:crypto_methods.Деньги зачислятся автоматически, курс зафиксирован на 30 минут',
          )}
        </p>
      </div>
      <div className={styles.qr}>
        <img src={qr} alt='qr-code' width={160} height={160} />
      </div>
    </div>
  )
}
