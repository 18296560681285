export const validEmailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
//Это регулярное выражение проверяет, является ли строка, на которую оно применяется, валидным email-адресом. Оно проверяет следующее:

//Строка начинается с одного или нескольких символов, которые могут быть буквами (в верхнем или нижнем регистре), цифрами, знаками подчеркивания, точками, процентами, знаками плюса и минуса.
//Затем должен быть символ "@"
//Затем может быть один или несколько символов, которые могут быть буквами (в верхнем или нижнем регистре), цифрами, дефисами и точками.
//Затем должна быть точка
//Затем должно быть две или более буквы (в верхнем или нижнем регистре), которые являются доменным именем верхнего уровня (например, com, org, edu, ru и т.д.).
//Если строка соответствует этому регулярному выражению, то она будет считаться валидным email-адресом.

export const validNameRegExp = /^[а-яА-Яa-zA-Z]+\s[а-яА-Яa-zA-Z]+\s?[а-яА-Яa-zA-Z]*$/
//Это регулярное выражение проверяет, состоит ли строка только из букв латинского алфавита (больших или маленьких), без пробелов и других символов. Например, оно пропустит строку "hello" и не пропустит строку "hello123".

export const replaceNotLetterSymbolsAndSpecialCharacters = /[^A-Za-z0-9_@./#&+-]/g
//Данное регулярное выражение означает, что в строке необходимо удалить все символы, которые НЕ являются буквами латинского алфавита (в верхнем или нижнем регистре), цифрами, символами _ @ . / # & + и -.

export const replaceEverythingExceptLetters = /[^A-Za-zА-Яа-я\s]/g
//Данное регулярное выражение будет пропускать только буквы латинского и кириллического алфавитов, а также пробелы. Все остальные символы будут заменяться на пустую строку при использовании метода replace с флагом g:
// valid: "Peter Kulakov", "Peter", " ",  invalid: "Petya228", "1", ",./ ..."

export const validSNGPhoneNumberRegExp =
  /^((\+7|7|8)+([0-9]){10})$|^((\+77|77)+([0-9]){9})$|^((\+375|375)+([0-9]){9})$/
//Для того, чтобы пропустить только номера телефонов в форматах России, Казахстана и Беларуси
//Данное регулярное выражение позволяет вводить номера телефонов в форматах:

//+7XXXXXXXXXX
//8XXXXXXXXXX
//7XXXXXXXXXX
//+77 (XXX) XXX-XX-XX
//77 (XXX) XXX-XX-XX
//+375 XX XXX-XX-XX
//+380 XX XXX-XX-XX

export const replaceAnyCharactersExceptNumberAndPlus = /[^\d+]/g
//Данное регулярное выражение находит все символы, которые не являются цифрами или знаком плюса (+)
