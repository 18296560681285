import React from 'react'
import { ReactComponent as IconSuccess } from 'assets/images/ui/icon-check-circle.svg'
import { ReactComponent as IconError } from 'assets/images/ui/icon-alert-circle.svg'

type Props = {
  type?: 'success' | 'error'
}
export const IconProgressPayment = ({ type }: Props) => {
  switch (type) {
    case 'success':
      return <IconSuccess />
    case 'error':
      return <IconError />
    default:
      return <IconError />
  }
}
