import { changeLanguage } from 'i18next'
import Cookies from 'js-cookie'

export const changeGlobalLanguage = (language: string) => {
  if (language) {
    changeLanguage(language)
    localStorage.setItem('_langStrict', JSON.stringify(true))
    Cookies.set('i18next', language)
  } else {
    changeLanguage('en')
    Cookies.set('i18next', 'en')
  }
}
