import { AvailableServicesType } from './../../helpers/types/availableMethods.type'
import { IBasicResponse } from './../../helpers/types/basicResponse.type'

export interface ITariffItem {
  group_name?: string
  group_label?: string
  tariffs?: IServiceItem[]
}

export enum InvoiceStatusEnum {
  CREATED = 'created',
  SUCCESS = 'success',
  FAIL = 'fail',
  REFUND = 'refund',
  EXPIRED = 'expired',
  PARTIAL_REFUND = 'partial_refund',
}

export interface IServiceItem {
  percent?: number
  min_sum?: number
  max_sum?: number
  service?: string
  label?: string
  discount_percent?: number
  is_final?: boolean
}

export interface IGetInvoiceInfo {
  id: string
  amount?: number
  expired_at?: string
  status?: InvoiceStatusEnum
  currency?: string
  comment?: string
  domain?: string
  success_url?: string
  fail_url?: string
  rating: null | number
  tariff_group?: ITariffItem[]
}

export interface IGetStepOne {
  signature: string
  service_type: AvailableServicesType
  currency: string
  amount: number
  use_email: boolean
  is_required_email: boolean
  fingerprint?: boolean
  url?: string
  address?: string
  currency_amount?: string
  qr_code?: string
  type?: string
  is_redirect?: boolean
  redirect_on_status?: boolean
  waiting?: boolean
}

export interface IGetInvoiceInfoResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetInvoiceInfo
}

export interface IGetInvoiceStatus {
  status?: InvoiceStatusEnum
  error_message?: string
}
export interface IGetInvoiceStatusResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetInvoiceStatus
}

export interface IGetReceipt {
  cheque_url: string
}

export interface IGetInvoiceReceiptResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetReceipt
}

export interface IGetStepOneResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetStepOne
}

export interface IPayCardFormPayload {
  cardNumber: number
  month: string
  year: string
  secretKey: string
  email?: string
  first_name?: string
  last_name?: string
  cvv?: string
  phone_number?: string
}

export interface IPayCardFormResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
    waiting?: boolean
    status?: string
    type: 'waiting_code'
  }
}

export interface ICheckCardResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    show_full_name_input: boolean
    show_cvv_input: boolean
    show_phone_input: boolean
    secret_key: string
  }
}

export interface IPayCardUrlPayload {
  secretKey: string
  email?: string
}

export interface ICheckCardParams {
  secret_key: string
  card_number: string
}

export interface IPayCardUrlResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
  }
}

export interface IPayQiwiUrlPayload {
  secretKey: string
  email?: string
}

export interface IPayCardUrlResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
  }
}
export interface IPayQiwiFormPayload {
  secretKey: string
  email?: string
  phone?: number
}

export interface IPayQiwiFormResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
  }
}
export interface IPaySbpUrlResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
  }
}

export interface IPaySbpUrlPayload {
  secretKey: string
  email?: string
}

export interface IPayCrypto {
  secretKey?: string
  email?: string
}
export interface IPayPerfect {
  secretKey?: string
  currency?: 'USD' | 'EUR' | string | undefined
  email?: string
}

export interface IPayCryptoResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    qr_code: string
    currency_amount: number
    address: string
    fingerprint: boolean
  }
}

export interface IPayPerfectResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    crypt_amount: number
    is_redirect: boolean
  }
}

export interface IGetMultiPerfect {
  wallet?: string
  id?: string
  amount?: number
  success_url?: string
  status_url?: string
  fail_url?: string
  description?: string
  form_name?: string
  currency?: 'USD' | 'EUR'
}

export interface IGetMultiPerfectResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetMultiPerfect
}

export interface IGetConvertCurrencyPayload {
  secretKey: string
  currencies: string[]
}
export interface IConvertCurrency {
  signature: string
  amounts: {
    currency: string
    amount: number
  }[]
}
export interface IGetConvertCurrency extends Omit<IBasicResponse, 'data'> {
  data?: IConvertCurrency
}
export interface IGetTds {
  pareq?: string
  md?: string
  url?: string
  term?: string
}
export interface IGetTdsResponse extends Omit<IBasicResponse, 'data'> {
  data?: IGetTds
}

export interface ISetEvaluationPayload {
  rating: number
  theme: string | undefined
  comment: string | undefined
}

export interface ISetEvaluationResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    id: number
    invoice_id: string
    rating: number
    theme: string
    comment: string
  }
}

export interface IAdditionalData {
  address?: string
  phone?: string
  credentials?: string
  email?: string
}
export interface ICreateWidgetPayload {
  widget_id: string
  additional_data?: IAdditionalData
}
export interface ICreateWidgetResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
  }
}

export interface IPayYooMoneyPayload {
  secretKey: string
  email?: string | undefined
}

export interface IPayYooMoneyResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
    crypt_amount?: number
  }
}

export interface IMultiYooMoneyPayload {
  code?: string
  internalId: string
  error?: string
}

export interface IMultiYooMoneyResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    status: number
  }
}

export interface IPayYooMoneyResponse extends Omit<IBasicResponse, 'data'> {
  data?: {
    url: string
    fingerprint: boolean
    is_redirect: boolean
    crypt_amount?: number
  }
}

// export interface IGetMultiYooMoney {

// }

export interface IGetInvoiceWithCheckout {
  checkout_id: string
  amount: number
  phone_number: string
  re_captcha_token: string
}
