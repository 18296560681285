interface LoadScriptParams {
  src: string;
  id?: string;
}

export const loadScript = ({ src, id }: LoadScriptParams): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.onload = () => resolve();
    script.onerror = () => reject();
    script.src = src;

    if (id) {
      script.id = id;
    }

    document.head.appendChild(script);
  });
