import { useCallback, useState } from 'react'
import { isIOS } from '../functions/isIOS'

export const useScrollLock = () => {
  const [savedScrollPosition, setSavedScrollPosition] = useState<any>(0)
  const lockScroll = useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth

    document.body.style.overflow = 'hidden'
    //паддинг нужен тк пропадает скролл и страница чуть дергается
    document.body.style.paddingRight = `${scrollBarCompensation}px`

    if (isIOS()) {
      setSavedScrollPosition(window.pageYOffset)
      document.body.style.position = 'fixed'
      document.body.style.top = `-${savedScrollPosition}px`
      document.body.style.width = '100%'
    }
  }, [])

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''

    if (isIOS()) {
      document.body.style.position = ''
      document.body.style.top = ``
      document.body.style.width = ''
      window.scrollTo(0, savedScrollPosition)
    }
  }, [])

  return {
    lockScroll,
    unlockScroll,
  }
}
