import React, { FormEvent, useMemo } from 'react'
import styles from './YooMoney.module.scss'
import { useParams } from 'react-router-dom'
import { PaymentOffer } from '../../../../components/PaymentOffer/PaymentOffer'
import { useMutation } from '@tanstack/react-query'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import { IPayYooMoneyPayload } from '../../../../services/invoice/invoice.service.types'
import {
  useMethodsContext,
} from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { Button } from '../../../../ui-lava/Button/Button'
import { useTranslation } from 'react-i18next'
import {  useForm } from 'react-hook-form'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { getCurrencySign } from '../../../../helpers/getCurrencySign'
import { useInvoice } from '../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { IResponseError } from '../../../../types/IResponse'

export const Yoomoney = (): JSX.Element => {
  const { invoiceId } = useParams()
  const { t } = useTranslation(['components'])
  const { setIsRedirecting, invoiceAmount } = useInvoice()

  const invoiceSumToPay = useMemo(
    () => `${invoiceAmount.amount} ${getCurrencySign(invoiceAmount.currency)}`,
    [invoiceAmount]
  )

  const { stepOneData, email } = useMethodsContext()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    mutate: payYooMoney,
    isLoading: isLoading_payYooMoney,
    isError: isError_payYooMoney,
    error: error_payYooMoney,
  } = useMutation(
    ['payYooMoney'],
    (data: IPayYooMoneyPayload) => InvoiceService.payYooMoney(data),
    {
      onSuccess: (data) => {
        if (data?.data?.data?.is_redirect && data?.data?.data?.url) {
          window.location.href = data?.data?.data?.url
          setIsRedirecting(true)
        }
      },
      onError: (_error: IResponseError) => null,
    }
  )

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault()

    payYooMoney({
      email: email === '' ? undefined : email,
      secretKey: stepOneData?.signature || '',
    })
  }

  if (isError_payYooMoney) {
    return (
      <StatusPayment
        type="error"
        title={t('components:status_payment.Произошла ошибка')}
        text={
          error_payYooMoney?.response?.data?.error ??
          t('components:status_payment.Произошла ошибка при загрузке курсов')
        }
      />
    )
  }

  return (
    <form className={styles.wrapper} onSubmit={handleSubmitForm}>
      <Button className={styles.button} disabled={isLoading_payYooMoney}>
        {t('ui:buttons.Оплатить')} {invoiceSumToPay}
      </Button>
      <PaymentOffer />
    </form>
  )
}
