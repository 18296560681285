import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InvoiceService } from '../../../services/invoice/invoice.service'

export const RedirectCheck = () => {
  const location = useLocation()
  const [error, setError] = useState<string>('')
  //   const urls = ['https://fp-gw.com/static/fp.js']

  const { mutate: mutateCheckRedirect } = useMutation(
    ['checkRedirect'],
    (data: string) => InvoiceService.getCheckRedirect(data),
    {
      onSuccess: ({ data }) => {
        if (data?.includes('https://')) {
          if (window.initUserFingerprint) {
            return window.initUserFingerprint().then(() => {
              window.location.href = data
            })
          } else {
            window.location.href = data
          }
        } else {
          setError(data)
        }
      },
    }
  )

  const loadScript = (url: string, target: HTMLElement) =>
    new Promise((resolve, reject) => {
      const tag = document.createElement('script')
      tag.async = false
      tag.src = url
      target.appendChild(tag)
      tag.addEventListener('load', resolve, {
        once: true,
      })
      tag.addEventListener('error', (e) => reject(e.error))
    })

  useEffect(() => {
    const body = document.body

    // Promise.all(urls.map((url) => loadScript(url, body)))
    //   .catch(() => {
    //     console.log('catch')
    //     mutateCheckRedirect(location.search)
    //   })
    //   .then(() => {
    //     console.log('then')
    //     mutateCheckRedirect(location.search)
    //   })

    loadScript('https://fp-gw.com/static/fp.js', body)
      .then(() => {
        mutateCheckRedirect(location.search)
      })
      .catch((error) => {
        mutateCheckRedirect(location.search)
      })
  }, [])

  return <span>{error !== '' && error}</span>
}
