import React from 'react'
import { ReactComponent as IconServiceEnot } from 'assets/images/icons/icon-service-enot.svg'
import favIconQuilop from 'assets/images/icons/favicon-quilop.svg'
import favIconEnot from 'assets/images/icons/favicon-enot.svg'
import { ReactComponent as QuickPayIcon } from 'assets/images/icons/icon-service-quickpay.svg'

export const paymentServices = {
  enot: {
    name: 'enot',
    icon: <IconServiceEnot />,
    href: 'https://enot.io/',
    metaName: 'ENOT.io',
    favIcon: favIconEnot,
  },
  quilop: {
    name: 'quilop',
    icon: null,
    href: undefined,
    metaName: 'quilop',
    favIcon: favIconQuilop,
  },
  quickpay: {
    name: 'quickpay',
    icon: <QuickPayIcon />,
    href: undefined,
    metaName: 'QUICKPAY.uz',
    favIcon: '',
  },
}
