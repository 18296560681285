import { AvailableMethodsEnum } from '../../helpers/types/availableMethods.type'
import { Card } from '../../views/Invoice/Methods/Card/Card'
import { Qiwi } from '../../views/Invoice/Methods/Qiwi/Qiwi'
import { Sbp } from '../../views/Invoice/Methods/Sbp/Sbp'
import { PerfectMoney } from '../../views/Invoice/Methods/PerfectMoney/PerfectMoney'
import { Yoomoney } from '../../views/Invoice/Methods/YooMoney/YooMoney'
import { CryptoMethods } from '../../views/Invoice/Methods/CryptoMethods/CryptoMethods'
import React from 'react'

interface Props {
  methodType?: AvailableMethodsEnum
}

export const AvailableMethods = ({ methodType }: Props) => {
  switch (methodType) {
    case AvailableMethodsEnum.CARD:
      return <Card />
    case AvailableMethodsEnum.QIWI:
      return <Qiwi />
    case AvailableMethodsEnum.SBP:
      return <Sbp />
    case AvailableMethodsEnum.PERFECT:
      return <PerfectMoney />
    case AvailableMethodsEnum.YOOMONEY:
      return <Yoomoney />
    case AvailableMethodsEnum.BITCOIN:
      return <CryptoMethods />
    case AvailableMethodsEnum.ETHEREUM:
      return <CryptoMethods />
    case AvailableMethodsEnum.LITECOIN:
      return <CryptoMethods />
    case AvailableMethodsEnum.TON:
      return <CryptoMethods />
    case AvailableMethodsEnum.TRX:
      return <CryptoMethods />
    case AvailableMethodsEnum.USDT_ERC20:
      return <CryptoMethods />
    case AvailableMethodsEnum.USDT_TRC20:
      return <CryptoMethods />
    case AvailableMethodsEnum.DASH:
      return <CryptoMethods />
  }

  return null
}
