export enum AvailableMethodsEnum {
  CARD = 'card',
  QIWI = 'qiwi',
  PERFECT = 'perfect_money',
  YOOMONEY = 'yoomoney',
  SBP = 'sbp',
  BITCOIN = 'bitcoin',
  LITECOIN = 'litecoin',
  ETHEREUM = 'ethereum',
  TRX = 'trx',
  DASH = 'dash',
  TON = 'ton',
  USDT_TRC20 = 'usdt_trc20',
  USDT_ERC20 = 'usdt_erc20',
}

export enum AvailableServicesType {
  CARD_FORM = 'card_form',
  CARD_URL_FORM = 'card_url_form',
  QIWI_FORM = 'qiwi_form',
  QIWI_URL_FORM = 'qiwi_url_form',
  SBP_URL_FORM = 'sbp_url_form',
  PERFECT_MONEY_FORM = 'perfect_money_form'
}
