import { ReactNode } from 'react'
import { AvailableMethodsEnum } from '../../../../../helpers/types/availableMethods.type'
import { IGetStepOne } from '../../../../../services/invoice/invoice.service.types'
import {
  PaymentCardInputValues
} from '../../../../../views/Invoice/Methods/Card/CardForm/PaymentCardInput/PaymentCardInput'

export enum MethodsStepEnum {
  EMAIL,
  PROCESS,
  FINAL,
}

export interface IEmailContentByMethod {
  title: string | JSX.Element
  text: string | JSX.Element
}
export type AvailableMethodsType = {
  [key in AvailableMethodsEnum | '']: ReactNode | JSX.Element | IEmailContentByMethod
}

export interface IMethodsContext {
  stepOneData: IGetStepOne | undefined
  onSubmitPayment: (value: PaymentCardInputValues) => void
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  methodsStep: MethodsStepEnum | undefined
  setMethodsStep: (value: MethodsStepEnum | undefined) => void
}
