import React from 'react'
import { IOrderDetails } from './OrderDetails.types'
import styles from './OrderDetails.module.scss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getDomainName } from '../../helpers/getDomainName/getDomainName'
import { FormStatus } from 'components/FormStatus/FormStatus'

export const OrderDetails = ({ orderData }: IOrderDetails) => {
  const { t } = useTranslation(['components'])
  // moment.locale('ru')s

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>{t('components:order_details.Детали платежа')}</h3>
      </div>

      <div className={styles.info}>
        <div className={styles.infoItem}>
          <h4 className={styles.itemTitle}>{t('components:order_details.Магазин')}</h4>

          <p className={styles.itemText}>{getDomainName(orderData?.domain) ?? orderData?.domain}</p>
        </div>

        {orderData?.comment && (
          <div className={styles.infoItem}>
            <h4 className={styles.itemTitle}>{t('components:order_details.Описание заказа')}</h4>

            <p className={styles.itemText}>{orderData?.comment}</p>
          </div>
        )}

        <div className={styles.infoItem}>
          <h4 className={styles.itemTitle}>{t('components:order_details.ID заказа')}</h4>

          <p className={styles.itemText}>{orderData?.id}</p>
        </div>
      </div>

      <div className={styles.expire}>
        <FormStatus
          type={'info'}
          title={`${t('components:order_details.Оплатить до')}
            ${moment(orderData?.expired_at)?.format('DD MMMM, HH:mm')}`}
        />
      </div>
    </div>
  )
}
