import React, { useEffect, useState } from 'react'
import './Card.scss'
import { useMethodsContext } from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { CardForm } from './CardForm/CardForm'
import { CardUrlForm } from './CardUrlForm/CardUrlForm'
import { AvailableServicesType } from '../../../../helpers/types/availableMethods.type'
import {
  IPayCardFormPayload,
  IPayCardFormResponse,
  IPayCardUrlPayload,
} from '../../../../services/invoice/invoice.service.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { PaymentOffer } from '../../../../components/PaymentOffer/PaymentOffer'
import { useTranslation } from 'react-i18next'
import { useInvoice } from '../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { IResponseError } from '../../../../types/IResponse'
import { MethodsContentSkeleton } from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContentSkeleton/MethodsContentSkeleton'
import {
  SidebarStatusEnum,
  useSidebar,
} from 'components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { PaymentLoader } from 'components/PaymentLoader/PaymentLoader'
import CardCodeForm from './CardCodeForm/CardCodeForm'

export const Card = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { t } = useTranslation(['components'])
  const { invoiceId } = useParams()

  const { setSidebarStatus } = useSidebar()
  const { setIsRedirecting, servicesCount } = useInvoice()
  const { stepOneData } = useMethodsContext()

  const [isMultiCard, setIsMultiCard] = useState<boolean>(false)
  const [isMultiError, setIsMultiError] = useState<boolean>(false)
  const [codeUrl, setCodeUrl] = useState<string>('')

  const {
    mutate: payCardFormMutate,
    isLoading: isLoading_payCardFormMutate,
    isError: isError_payCardFormMutate,
    error: error_payCardFormMutate,
  } = useMutation(
    ['payCardForm'],
    (data: IPayCardFormPayload) => InvoiceService.payCardForm(data),
    {
      onSuccess: (data) => {
        const info = data.data.data
        if (info?.is_redirect) {
          window.location.href = data?.data?.data?.url ?? ''
          setIsRedirecting(true)
          return
        } else if (info?.type === 'waiting_code') {
          setCodeUrl(info.url)
        }
      },
      onError: (_error: IResponseError) => null,
    }
  )
  const {
    mutate: payCardUrlMutate,
    isLoading: isLoading_payCardUrlMutate,
    isError: isError_payCardUrlMutate,
    error: error_payCardUrlMutate,
  } = useMutation(['payCardUrl'], (data: IPayCardUrlPayload) => InvoiceService.payCardUrl(data), {
    onSuccess: (data) => {
      // setMethodsStep(MethodsStepEnum.FINAL)
      if (data?.data?.data?.url && data?.data?.data?.is_redirect) {
        window.location.href = data?.data?.data?.url
        setIsRedirecting(true)
      }
    },
    onError: (_error: IResponseError) => null,
  })

  const { error: error_payCardMulti } = useQuery<
    AxiosResponse<IPayCardFormResponse>,
    IResponseError
  >(['payCardMulti'], () => InvoiceService.payCardMulti({ invoiceId: invoiceId ?? '' }), {
    onSuccess: (data) => {
      if (data?.data?.data?.url && data?.data?.data?.is_redirect) {
        if (!data?.data?.data?.fingerprint || !window.initUserFingerprint) {
          window.location.href = data?.data?.data?.url ?? ''
          setIsRedirecting(true)
          return
        }
        // Обработка промиса и редирект на оплату
        return window.initUserFingerprint().then(() => {
          window.location.href = data?.data?.data?.url ?? ''
          setIsRedirecting(true)
        })
      }
    },
    onError: (_data: IResponseError) => {
      setSidebarStatus(SidebarStatusEnum.EXPANDED)
      setIsMultiCard(false)
      setIsMultiError(true)

      //тут сделал проверку, что в случае перезагрузку страницы в процессе, с инфо всегда придет 1 метод. В случае, если просто ошибка, методов может быть больше
      servicesCount && servicesCount < 2 && queryClient.invalidateQueries(['getInvoiceInfo'])
    },
    enabled: isMultiCard,
    refetchInterval: 5000,
    retry: 1,
  })

  useEffect(() => {
    if (stepOneData?.waiting) {
      setIsMultiCard(true)
    } else {
      setIsMultiCard(false)
    }
  }, [stepOneData])

  const onSubmitCardService = (value: any, secretKey?: string) => {
    if (stepOneData?.service_type === AvailableServicesType.CARD_FORM) {
      payCardFormMutate({
        cardNumber: value.cardNumber,
        month: value.expireMonth,
        year: value.expireYear,
        secretKey: secretKey ?? '',
        email: value?.email === '' ? undefined : value?.email,
        first_name: value?.first_name,
        last_name: value?.last_name,
        phone_number: value.phone_number,
        cvv: value?.cvv,
      })
    } else {
      payCardUrlMutate({
        secretKey: stepOneData?.signature ?? '',
        email: value?.email === '' ? undefined : value?.email,
      })
    }
  }

  const getCardContent = () => {
    switch (stepOneData?.service_type) {
      case AvailableServicesType.CARD_URL_FORM:
        return <CardUrlForm onSubmitCard={onSubmitCardService} />
      case AvailableServicesType.CARD_FORM:
        return <CardForm onSubmitCard={onSubmitCardService} />
      default:
        return <MethodsContentSkeleton />
    }
  }

  if (isError_payCardFormMutate || isError_payCardUrlMutate || isMultiError) {
    // setSidebarStatus(SidebarStatusEnum.CLOSED)
    return (
      <StatusPayment
        // action={<Button onClick={() => payCardFormMutate()}>Попробовать еще раз</Button>}
        title={t('components:status_payment.Произошла ошибка')}
        text={
          error_payCardUrlMutate?.response?.data?.error ??
          error_payCardFormMutate?.response?.data?.error ??
          error_payCardMulti?.response?.data?.error ??
          t(
            'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку'
          )
        }
      />
    )
  }

  if (isLoading_payCardFormMutate || isLoading_payCardUrlMutate) {
    return <MethodsContentSkeleton />
  }

  if (codeUrl || stepOneData?.type === 'waiting_code') {
    return <CardCodeForm codeUrl={codeUrl || (stepOneData?.url ?? '')} />
  }

  if (isMultiCard) {
    return <PaymentLoader text={t('components:payment_loader.Идет переадресация')} />
  }

  return (
    <div className="metod-wrapper">
      {/* <div className="card__input-wrapper">
          <PaymentCardInput onChange={(values) => handlePaymentCardInput(values)} />
        </div>

        <Button
          className="button__payment"
          onClick={(e) => {
            e.preventDefault()

            onSubmitPayment(cardValues)
            // setCardSteps(CardStepsEnum.CARD_STATUS)
          }}
        >
          Оплатить
        </Button> */}

      {getCardContent()}
      <PaymentOffer />
    </div>
  )
}
