export const getLanguageFullName = (code: string) => {
  switch (code) {
    case 'ru':
      return 'русский'
    case 'en':
      return 'english'
    case 'uz':
      return "o'zbekcha"
  }
  // const regionNames = new Intl.DisplayNames([code], { type: 'language' })
  // return regionNames.of(code)
}
