import { ToggleButton } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'
import React, { SetStateAction, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyledToggleButtonGroup } from 'ui-lava/Form/ToggleButtonGroup/ToggleButtonGroup'
import { useMethodsContext } from '../../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { PaymentOffer } from '../../../../../components/PaymentOffer/PaymentOffer'
import { getCurrencySign } from '../../../../../helpers/getCurrencySign'
import { Button } from '../../../../../ui-lava/Button/Button'
import { InputLabel } from '../../../../../ui-lava/Input/InputLabel/InputLabel'
import { PerfectMoneySkeleton } from '../PerfectMoneySkeleton/PerfectMoneySkeleton'
import styles from './PerfectMoneySelect.module.scss'
import { deleteSpaces } from '../../../../../helpers/inputDeleteSpaces'
import { useInvoice } from '../../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { Patterns } from '../../../../../enums/patterns.const'

export interface IPerfectMoneySelect {
  currencyData: { currency: string; amount: number }[] | undefined
  selectedCurrency: 'USD' | 'EUR'
  setSelectedCurrency: React.Dispatch<SetStateAction<'USD' | 'EUR'>>
  handleSubmit: (value: string) => void
  isLoading: boolean
}

const PerfectMoneySelect = ({
  selectedCurrency,
  setSelectedCurrency,
  handleSubmit,
  currencyData,
  isLoading,
}: IPerfectMoneySelect) => {
  const { stepOneData } = useMethodsContext()
  const { t } = useTranslation(['components', 'ui'])

  const isMutatingPay = useIsMutating(['payPerfect'])
  const { invoiceAmount } = useInvoice()

  const {
    handleSubmit: handleSubmitForm,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  })

  const invoiceSumToPay = useMemo(
    () => `${invoiceAmount.amount} ${getCurrencySign(invoiceAmount.currency)}`,
    [invoiceAmount]
  )

  const handleChangeToggle = (e: React.MouseEvent<HTMLElement>, currency: 'USD' | 'EUR' | null) => {
    if (currency !== null) {
      setSelectedCurrency(currency)
    }
  }

  const onSubmitForm = ({ email }: { email: string }) => {
    handleSubmit && handleSubmit(email)
  }

  return (
    <>
      {isLoading || !currencyData ? (
        <PerfectMoneySkeleton />
      ) : (
        <>
          <div className={styles.switch}>
            <StyledToggleButtonGroup
              value={selectedCurrency}
              onChange={handleChangeToggle}
              exclusive
              aria-label="toggle currency"
            >
              {currencyData?.map((item, index) => {
                return (
                  <ToggleButton
                    key={item.currency}
                    // value={`${item.currencyMark} ${item.amount}`}
                    value={item.currency}
                    aria-label={item.currency}
                    className="filters__item"
                    disableRipple={true}
                  >
                    {t('components:perfect_money.selector', {
                      currency: getCurrencySign(item?.currency),
                      amount: item?.amount?.toFixed(2),
                    })}
                    {/* {item.amount?.toFixed(2)} {getCurrencySign(item?.currency)} */}
                    <span className="item__currency-name">{item.currency}</span>
                  </ToggleButton>
                )
              })}
            </StyledToggleButtonGroup>
          </div>

          <form onSubmit={handleSubmitForm(onSubmitForm)}>
            {stepOneData?.use_email && (
              <div className={styles.input}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue={''}
                  rules={{
                    required: {
                      value: false,
                      message: t('components:card_form.Введите email для чека'),
                    },
                    minLength: {
                      value: 3,
                      message: t('components:card_form.Неверный email'),
                    },
                    pattern: {
                      value: Patterns.EMAIL,
                      message: t('components:card_form.Введите корректный email адрес'),
                    },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputLabel
                        id="filled-basic"
                        label={t('components:card_form.Email для чека')}
                        variant="filled"
                        value={value}
                        onChange={(e) => {
                          deleteSpaces(e)
                          onChange(e)
                        }}
                        customError={errors?.email?.message || ''}
                        autoFocus
                        required={stepOneData.is_required_email}
                      />
                    )
                  }}
                />
              </div>
            )}

            <Button className={styles.button} disabled={!!isMutatingPay}>
              {t('ui:buttons.Оплатить')} {invoiceSumToPay}
            </Button>
          </form>
        </>
      )}

      <PaymentOffer />
    </>
  )
}

export default PerfectMoneySelect
