import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'

interface ISidebarContext {
  visible: boolean
  openSidebar: () => void
  sidebarStatus: SidebarStatusEnum | null
  setSidebarStatus: (_sidebarStatus: SidebarStatusEnum) => void
  isServiceSelected: boolean
  setIsServiceSelected: (_isServiceSelected: boolean) => void
}

const SidebarContext = React.createContext<ISidebarContext>({
  visible: true,
  openSidebar: () => null,
  sidebarStatus: {} as SidebarStatusEnum,
  setSidebarStatus: () => null,
  isServiceSelected: false,
  setIsServiceSelected: () => null,
})

export const useSidebar = () => useContext(SidebarContext)

interface ISidebarProvider {
  children?: React.ReactNode
}

export enum SidebarStatusEnum {
  CLOSED,
  COLLAPSED,
  EXPANDED,
}

export const SidebarProvider = ({ children }: ISidebarProvider) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(true)
  const [isServiceSelected, setIsServiceSelected] = useState<boolean>(false)
  const [sidebarStatus, setSidebarStatus] = useState<SidebarStatusEnum | null>(null)

  const openSidebar = () => setShowSidebar((prev) => !prev)

  const isLaptopS = useMediaQuery('(min-width: 576px)')

  useEffect(() => {
    isLaptopS ? setShowSidebar(true) : setShowSidebar(false)
  }, [isLaptopS])

  return (
    <SidebarContext.Provider
      value={{
        visible: showSidebar,
        openSidebar,
        sidebarStatus,
        setSidebarStatus,
        isServiceSelected,
        setIsServiceSelected,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
