import React from 'react'
import styles from './FormStatus.module.scss'
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-form-info.svg'
import { ReactComponent as IconError } from '../../assets/images/icons/icon-form-error.svg'
import cn from 'classnames'

interface IFormStatusProps {
  type: 'info' | 'error' | 'warning';
  title: string;
  text?: string;
  outlined?: boolean;
}

interface IGetIcon {
  [value: string]: JSX.Element
}
export const FormStatus = ({
  type,
  title,
  text,
  outlined = false,
}: IFormStatusProps) => {
  const getIcon: IGetIcon = {
    info: <IconInfo />,
    error: <IconError />,
    warning: <IconInfo />,
  }
  return (
    <div className={cn(styles.formStatus, styles[type], outlined && styles.outlined)}>
      <div className={styles.icon}>{getIcon[type]}</div>

      <div className={styles.content}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {text && <p className={styles.text}>{text}</p>}
      </div>
    </div>
  )
}
