import React, { FormEvent, useState } from 'react'
import { Button } from '../../../../../ui-lava/Button/Button'
import { useTranslation } from 'react-i18next'

import { isValidPhone } from '../../../../../helpers/validations'
import { useInvoice } from '../../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { getCurrencySign } from '../../../../../helpers/getCurrencySign'
import { availableQiwiCountries } from '../../../../../config/phone_input.data'
import { InputPhone } from 'ui-lava/Input/InputPhone/InputPhone'

import styles from './QiwiForm.module.scss'

export const QiwiForm = ({
  onSubmitForm,
}: {
  onSubmitForm: ({ phone }: { phone: string }) => void
}) => {
  const { t } = useTranslation(['components', 'ui'])
  const [phone, setPhone] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { invoiceAmount } = useInvoice()

  const handleQiwiFormSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!isValidPhone(phone)) {
      setError(t('components:qiwi.Введите корректный номер'))
      return
    }

    error === '' && onSubmitForm({ phone })
  }

  const invoiceSumToPay = `${invoiceAmount.amount} ${getCurrencySign(invoiceAmount.currency)}`

  return (
    <form onSubmit={handleQiwiFormSubmit}>
      <InputPhone
        className={styles.input}
        placeholder={t('components:qiwi.Введите номер')}
        onChange={(value) => {
          setError('')
          setPhone(value)
        }}
        inputProps={{
          required: true,
          autoFocus: true,
        }}
        preferredCountries={['by', 'ru']}
        onlyCountries={availableQiwiCountries}
        error={error}
      />

      <Button className={styles.button}>
        {t('ui:buttons.Оплатить')} {invoiceSumToPay}
      </Button>
    </form>
  )
}
