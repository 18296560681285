import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconInfo } from 'assets/images/icons/snackbar/icon-info.svg'
import { ReactComponent as IconWarning } from 'assets/images/icons/snackbar/icon-warning.svg'
import { ReactComponent as IconError } from 'assets/images/icons/snackbar/icon-error.svg'
import { ReactComponent as IconSuccess } from 'assets/images/icons/snackbar/icon-success.svg'
import { ReactComponent as IconClose } from 'assets/images/icons/icon-close.svg'
import { SnackbarMessage, SnackbarContent } from 'notistack'
import styles from './CustomSnackbar.module.scss'
import cn from 'classnames'

export type IVariants = 'success' | 'error' | 'default' | 'info' | 'warning'

interface ICustomSnackbar {
  message?: SnackbarMessage
  description: string
  variant: IVariants
  onClose: () => void
  onClickAction?: () => void
}

interface IDeterminateIcon {
  [variant: string]: React.ReactElement
}

const icons: IDeterminateIcon = {
  ['success']: <IconSuccess />,
  ['error']: <IconError />,
  ['info']: <IconInfo />,
  ['warning']: <IconWarning />,
}

export const CustomSnackbar = React.forwardRef<HTMLDivElement, ICustomSnackbar>(
  ({ message, variant, description, onClose, onClickAction }, ref, ...rest) => {
    const { t } = useTranslation(['components'])

    return (
      <SnackbarContent ref={ref} role="alert" {...rest}>
        <div className={styles.background}>
          <div className={cn(styles.snackbar, styles[variant])}>
            <div className={styles.snackbar__container}>
              {icons[variant] ? icons[variant] : <></>}
              <div className={styles.snackbar__container_data}>
                <p className={styles.snackbar__container_data_title}>
                  {message || t('components:status_payment.Произошла ошибка')}
                </p>

                {description && (
                  <p className={styles.snackbar__container_data_message}>{description}</p>
                )}
              </div>

              {onClickAction && (
                <button className={styles.snackbar__container_button} onClick={onClickAction}>
                  Подробнее
                </button>
              )}
              <button onClick={onClose} className={styles.snackbar__container_buttonClose}>
                <IconClose />
              </button>
            </div>
          </div>
        </div>
      </SnackbarContent>
    )
  }
)

CustomSnackbar.displayName = 'CustomSnackbar'
