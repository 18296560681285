import { MethodsStepEnum } from '../Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent.type'
import { EmailPayment } from '../Layout/Invoice/InvoiceContent/MethodsContent/EmailPayment/EmailPayment'
import React from 'react'
import { AvailableMethods } from '../AvailableMethods/AvailableMethods'
import { AvailableMethodsEnum } from '../../helpers/types/availableMethods.type'
import { StatusPayment } from '../StatusPayment/StatusPayment'
import { IInvoiceStatus } from '../Layout/Invoice/InvoiceContext/InvoiceContext'
import { MethodsContentSkeleton } from '../Layout/Invoice/InvoiceContent/MethodsContent/MethodsContentSkeleton/MethodsContentSkeleton'

type Props = {
  methodName: AvailableMethodsEnum
  methodStep?: MethodsStepEnum
  invoiceStatus: IInvoiceStatus
}

export const MethodsContentByStep = ({ methodName, methodStep, invoiceStatus }: Props) => {
  switch (methodStep) {
    case MethodsStepEnum.EMAIL:
      return <EmailPayment />
    case MethodsStepEnum.PROCESS:
      return <AvailableMethods methodType={methodName} />
    case MethodsStepEnum.FINAL:
      return (
        <StatusPayment
          text={
            invoiceStatus?.options?.text ? invoiceStatus.options.text : String(invoiceStatus?.text)
          }
          title={
            invoiceStatus?.options?.title
              ? invoiceStatus.options.title
              : String(invoiceStatus?.title)
          }
          action={
            invoiceStatus?.options?.action
              ? invoiceStatus.options.action
              : String(invoiceStatus?.action)
          }
          type={invoiceStatus?.options?.type ? invoiceStatus.options.type : invoiceStatus?.type}
        />
      )
  }

  return <MethodsContentSkeleton />
}
