import { Skeleton } from '@mui/material'
import React from 'react'
import { randomInteger } from '../../../../../../helpers/randomInteger'
import styles from './MethodsContentSkeleton.module.scss'

export const MethodsContentSkeleton = ({ withHeader = false }: { withHeader?: boolean }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {withHeader && (
          <div className={styles.serviceHeader}>
            <Skeleton variant="circular" width={48} height={48} className={styles.image} />
            <Skeleton
              variant="text"
              width={randomInteger(150, 240)}
              height={32}
              className={styles.text}
            />
          </div>
        )}

        <div className={styles.serviceContent}>
          <div className={styles.description}>
            <Skeleton variant="text" width={'40%'} height={24} className={styles.descriptionText} />
            <Skeleton variant="text" width={'70%'} height={24} className={styles.descriptionText} />
          </div>

          <div className={styles.inputs}>
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={60}
              className={styles.input}
              sx={{ borderRadius: '10px' }}
            />
          </div>

          <div className={styles.button}>
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={60}
              sx={{ borderRadius: '10px' }}
            />
          </div>

          <div className={styles.offer}>
            <div className={styles.offerText}>
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={18}
                sx={{ borderRadius: '10px' }}
              />
            </div>
            <div className={styles.offerText}>
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={18}
                sx={{ borderRadius: '10px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
