import { useEffect, useState } from 'react'
import { loadScript } from '../helpers/load_script'

interface CaptchaServiceParams {
  reCAPTCHASiteKey: string
  url: string
}

type ActionType = string
type CaptchaStatus = 'loading' | 'loaded' | 'error'
type Listener = (state: State) => void
type State = ReturnType<CaptchaService['getSnapshot']>

class CaptchaService {
  private reCAPTCHASiteKey: string
  private url: string
  public listeners = new Set<Listener>()
  private status: CaptchaStatus = 'loading'

  constructor({ reCAPTCHASiteKey, url }: CaptchaServiceParams) {
    this.reCAPTCHASiteKey = reCAPTCHASiteKey
    this.url = url
  }

  private load = async () => {
    try {
      await loadScript({ src: this.url, id: 'recaptcha-script' })

      window.grecaptcha.ready(() => {
        this.status = 'loaded'
        this.runListeners(this.getSnapshot())
      })
    } catch (error) {
      this.status = 'error'
      this.runListeners(this.getSnapshot())
    }
  }

  private runListeners = (state: State) => {
    this.listeners.forEach((listener) => listener(state))
  }

  subscribe = (listener: Listener) => {
    this.listeners.add(listener)
    this.load()

    return () => {
      this.listeners.delete(listener)
    }
  }

  execute =
    (callbackBefore: () => void, callbackAfter: () => void) => async (action: ActionType) => {
      if (this.status !== 'loaded') {
        return ''
      }

      callbackBefore()
      const executed = await window.grecaptcha.execute(this.reCAPTCHASiteKey, { action })
      callbackAfter()

      return executed
    }

  getSnapshot = () => {
    return {
      status: this.status,
      isLoading: this.status === 'loading',
      execute: this.execute,
    }
  }
}

const captchaService = new CaptchaService({
  reCAPTCHASiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '',
  url: `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
})

export const useGoogleRecaptcha = () => {
  const [state, setState] = useState(captchaService.getSnapshot())
  const [isFetching, setIsFetching] = useState(false)

  const toggleFetching = (status: boolean) => () => setIsFetching(status)

  useEffect(() => captchaService.subscribe((newState) => setState(newState)), [])

  return {
    ...state,
    execute: state.execute(toggleFetching(true), toggleFetching(false)),
    isFetching,
  }
}
