import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useMethodsContext,
} from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import {
  MethodsContentSkeleton,
} from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContentSkeleton/MethodsContentSkeleton'
import { PaymentOffer } from '../../../../components/PaymentOffer/PaymentOffer'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { AvailableServicesType } from '../../../../helpers/types/availableMethods.type'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import { IPaySbpUrlPayload } from '../../../../services/invoice/invoice.service.types'
import styles from './Sbp.module.scss'
import { SbpUrl } from './SbpUrl/SbpUrl'
import { useInvoice } from '../../../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { IResponseError } from '../../../../types/IResponse'

export const Sbp = () => {
  const { setIsRedirecting } = useInvoice()
  const { t } = useTranslation(['components'])

  const { stepOneData, email } = useMethodsContext()
  const {
    handleSubmit,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const handleSubmitForm = () => {
    if (stepOneData?.service_type === AvailableServicesType.SBP_URL_FORM) {
      paySbpUrl({
        secretKey: stepOneData?.signature ?? '',
        email: email === '' ? undefined : email,
      })
    }
  }

  const {
    mutate: paySbpUrl,
    isLoading: isLoading_paySbpUrl,
    isError: isError_paySbpUrl,
    error: error_paySbpUrl,
  } = useMutation(['paySbpUrl'], (data: IPaySbpUrlPayload) => InvoiceService.paySbpUrl(data), {
    onSuccess: (data) => {
      if (data?.data?.data?.url && data?.data?.data?.is_redirect) {
        window.location.href = data?.data?.data?.url
        setIsRedirecting(true)
      }
    },
    onError: (_error: IResponseError) => null,
  })

  const getSbpContent = () => {
    switch (stepOneData?.service_type) {
      case AvailableServicesType.SBP_URL_FORM:
        return <SbpUrl onSubmitForm={handleSubmitForm} />
      default:
        return <MethodsContentSkeleton />
    }
  }
  if (isLoading_paySbpUrl) {
    return <MethodsContentSkeleton />
  }

  if (isError_paySbpUrl) {
    return (
      <StatusPayment
        // action={<Button onClick={() => payCardFormMutate()}>Попробовать еще раз</Button>}
        title={t('components:status_payment.Произошла ошибка')}
        text={
          error_paySbpUrl?.response?.data?.error ??
          t(
            'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку',
          )
        }
      />
    )
  }
  return (
    <div>
      {/* {stepOneData.use_email && (
        <div className={styles.input}>
          <Controller
            control={control}
            name="email"
            defaultValue={''}
            rules={{
              required: {
                value: true,
                message: 'Введите email для чека',
              },
              minLength: {
                value: 3,
                message: 'Неверный email',
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Введите корректный email адрес',
              },
            }}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <InputLabel
                  id="filled-basic"
                  label="Email для чека"
                  variant="filled"
                  value={value}
                  onChange={onChange}
                  customError={errors?.email?.message ?? ''}
                  autoFocus
                />
              )
            }}
          />
        </div>
      )} */}

      {/* <Button
        className={styles.button}
        // onClick={(e) => {
        //   e.preventDefault()
        //   onSubmitPayment()
        // }}
      >
        Оплатить
      </Button> */}
      <div className={styles.content}>{getSbpContent()}</div>

      <PaymentOffer />
    </div>
  )
}
