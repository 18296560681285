import React, { useEffect } from 'react'
import { InputLabel } from '../../ui-lava/Input/InputLabel/InputLabel'
import styles from './Widget.module.scss'
import cn from 'classnames'
import { ReactComponent as IconEnot } from 'assets/images/icons/enot-logo-widget.svg'
import { useLocation, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { InvoiceService } from '../../services/invoice/invoice.service'
import { Controller, useForm } from 'react-hook-form'
import { ButtonWidget } from './ButtonWidget/ButtonWidget'
import { getCurrencySign } from '../../helpers/getCurrencySign'
import { IAdditionalData, ICreateWidgetPayload } from 'services/invoice/invoice.service.types'
import {
  replaceAnyCharactersExceptNumberAndPlus,
  replaceEverythingExceptLetters,
  replaceNotLetterSymbolsAndSpecialCharacters,
  validEmailRegExp,
  validNameRegExp,
  validSNGPhoneNumberRegExp,
} from 'helpers/regExpHelper'

export interface IParamsObj {
  amount?: string
  color?: string
  comment?: string
  currency?: string
  email?: string
  size?: string
  address?: string
  phone?: string
  credentials?: string
}

export const Widget = () => {
  const { widgetId, widgetType } = useParams()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search.replace(/&amp;/g, '&'))
  const paramsObj: IParamsObj = Object.fromEntries(searchParams.entries())

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const createWidgetQuery = useMutation(
    ['createWidgetQuery'],
    (data: ICreateWidgetPayload) => InvoiceService.createWidget(data),
    {
      onSuccess: ({ data }) => {
        if (data?.data?.url) {
          if (widgetType === 'qr' || widgetType === 'link') {
            window.location.href = data?.data?.url
          } else {
            window.open(data?.data?.url)
          }
        }
      },
    }
  )

  useEffect(() => {
    document.body.classList.add('white-background')
  }, [])

  const formatCurrency = (amount?: string, currency?: string) => {
    const formattedAmount = Number(amount)

    if (isNaN(formattedAmount) || !amount) {
      return '1000'
    }

    return new Intl.NumberFormat('ru', {
      currency: currency,
      style: 'currency',
      maximumFractionDigits: 2,
    })
      .format(formattedAmount)
      .replace(',', '.')
  }

  const handleSubmitForm = (data?: IAdditionalData) => {
    createWidgetQuery.mutate({
      widget_id: widgetId ?? '',
      additional_data: {
        phone: data?.phone ? data?.phone : undefined,
        credentials: data?.credentials ? data.credentials : undefined,
        address: data?.address ? data.address : undefined,
        email: data?.email ? data.email : undefined,
      },
    })
  }

  const getWidgetContent = () => {
    switch (widgetType) {
      case 'form':
        return (
          <div
            className={cn(
              styles.widgetPreview,
              styles[paramsObj?.color ?? 'white'],
              styles[paramsObj.size ?? 'large']
            )}
          >
            <div className={styles.header}>
              <h1 className={styles.amount}>
                {paramsObj.amount === ''
                  ? `1000 ${getCurrencySign(paramsObj.currency as string)}`
                  : formatCurrency(paramsObj.amount, paramsObj.currency)}{' '}
              </h1>
              <p className={styles.description}>
                {paramsObj?.comment !== '' ? paramsObj.comment : ''}
              </p>
            </div>

            <form
              className={styles.content}
              onSubmit={handleSubmit(handleSubmitForm)}
              id="formWidget"
            >
              <div className={styles.inputWrapper}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: 'Введите email',
                    },
                    pattern: {
                      value: validEmailRegExp,
                      message: 'Введите корректный email адрес',
                    },
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputLabel
                        id="filled-basic"
                        label={'Почта'}
                        variant="filled"
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const newValue = e.target.value.replace(
                            replaceNotLetterSymbolsAndSpecialCharacters,
                            ''
                          )
                          onChange(newValue)
                        }}
                        autoFocus
                        customError={errors.email?.message as string}
                        hideErrorMessage={true}
                        className={styles.innerInput}
                        customTheme={paramsObj.color}
                      />
                    )
                  }}
                />
              </div>

              {!!paramsObj.credentials && (
                <div className={styles.inputWrapper}>
                  <Controller
                    control={control}
                    name="credentials"
                    defaultValue={''}
                    rules={{
                      required: {
                        value: true,
                        message: 'Введите данные',
                      },
                      pattern: {
                        value: validNameRegExp,
                        message: '',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputLabel
                          id="filled-basic"
                          label={'Фамилия, имя и отчество'}
                          variant="filled"
                          value={value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValue = e.target.value.replace(
                              replaceEverythingExceptLetters,
                              ''
                            )
                            onChange(newValue)
                          }}
                          customError={errors.credentials?.message as string}
                          hideErrorMessage={true}
                          className={styles.innerInput}
                          customTheme={paramsObj.color}
                        />
                      )
                    }}
                  />
                </div>
              )}

              {!!paramsObj.address && (
                <div className={styles.inputWrapper}>
                  <Controller
                    control={control}
                    name="address"
                    defaultValue={''}
                    rules={{
                      required: {
                        value: true,
                        message: 'Введите адрес',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputLabel
                          id="filled-basic"
                          label={'Адрес'}
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          customError={errors.address?.message as string}
                          className={styles.innerInput}
                          hideErrorMessage={true}
                          customTheme={paramsObj.color}
                        />
                      )
                    }}
                  />
                </div>
              )}

              {!!paramsObj.phone && (
                <div className={styles.inputWrapper}>
                  <Controller
                    control={control}
                    name="phone"
                    defaultValue={''}
                    rules={{
                      required: 'Введите номер телефона',
                      pattern: {
                        value: validSNGPhoneNumberRegExp,
                        message: 'Введите корректный номер телефона',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <InputLabel
                          id="filled-basic"
                          label={'Телефон'}
                          variant="filled"
                          value={value}
                          onChange={(e) => {
                            const newValue = e.target.value.replace(
                              replaceAnyCharactersExceptNumberAndPlus,
                              ''
                            )
                            onChange(newValue)
                          }}
                          customError={errors.phone?.message as string}
                          //hideErrorMessage={true}
                          className={styles.innerInput}
                          customTheme={paramsObj.color}
                        />
                      )
                    }}
                  />
                </div>
              )}
            </form>

            <div className={styles.footer}>
              <button
                className={styles.payButton}
                form="formWidget"
                disabled={!isValid || createWidgetQuery.isLoading}
              >
                Оплатить{' '}
                {paramsObj.amount === ''
                  ? `1000 ${getCurrencySign(paramsObj.currency as string)}`
                  : formatCurrency(paramsObj.amount, paramsObj.currency)}{' '}
              </button>

              <div className={styles.safePayments}>
                <span className={styles.text}>Безопасные платежи с</span>
                <IconEnot />
              </div>
            </div>
          </div>
        )

      case 'button':
        return <ButtonWidget params={paramsObj} handleSubmit={handleSubmitForm} />
      default:
        return <>Redirecting</>
    }
  }

  useEffect(() => {
    if (widgetType === 'qr' || widgetType === 'link') {
      handleSubmitForm({})
    }
  }, [widgetType])
  return <div>{getWidgetContent()}</div>
}
