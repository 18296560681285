import { Skeleton } from '@mui/material'
import React from 'react'

import styles from './PerfectMoneySkeleton.module.scss'

export const PerfectMoneySkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton variant="rectangular" width={'100%'} height={60} className={styles.switch} />
      <Skeleton variant="rectangular" width={'100%'} height={60} className={styles.email} />
      <Skeleton variant="rectangular" width={'100%'} height={60} className={styles.button} />

      {/* <Skeleton variant="text" width={'80%'} height={36} className={styles.text} /> */}
    </div>
  )
}
