// export const isValidLogin = (login: string) => {
//     if (!login) return false;

//     return isValidEmail(login) || isValidPhone(login);
// };

export const isValidEmail = (mail: string) => {
  const emailRegx = new RegExp(/^([\w.*-]+@([\w-]+\.)+[\w-]{2,11})?$/)
  return emailRegx.test(mail)
}

export const isValidPhone = (str: string) => {
  const numberRegx = new RegExp(/^\+?(?:[ ()-]*\d){8,15}[() -]*$/)
  return numberRegx.test(str)
}

export const isValidMerchant = (merchant: string) => {
  if (!merchant) return false

  const regex = new RegExp(/^(?:(ftp|http|https):\/\/)?(?:[a-z|а-я|0-9|-]+\.)+[a-z|а-я]{2,24}$/)

  return regex.test(merchant)
}

export const detectCardType = (card: string) => {
  card = card.replace(/\s/g, '')

  const code = {
    visa: /^4[0-9]\d+$/,
    mastercard: /^5[1-5][0-9]\d+$/,
    mir: /^(22)\d+$/,
    // maestro:
    //     /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    maestro: /^(50|56|57|58|61|68|67|69)\d+$/,
  }

  for (const key in code) {
    if (code[key as keyof typeof code] && code[key as keyof typeof code].test(card)) {
      return key
    }
  }
}
