import React, { useRef } from 'react'
import { useSnackbar as useDefaultSnackbar, OptionsObject } from 'notistack'
import { IVariants, CustomSnackbar } from '../ui-lava/CustomSnackbar/CustomSnackbar'

export const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useDefaultSnackbar()
  const ref = useRef<HTMLDivElement>(null)

  return (
    message?: string,
    options?: OptionsObject &
      Partial<{ variant: IVariants, description?: string, onClickAction?: () => void }>,
  ) => {
    enqueueSnackbar(message, {
      ...options,

      content: (key) => {
        const { variant } = options || { variant: undefined }
        const description = options?.description
        const onClickAction = options?.onClickAction

        return (
          <CustomSnackbar
            onClose={() => closeSnackbar(key)}
            onClickAction={onClickAction}
            description={description || ''}
            message={message}
            variant={variant || 'default'}
            ref={ref}
          />
        )
      },
    })
  }
}