import React from 'react'
import './Service.scss'
import { useTranslation } from 'react-i18next'
import { useInvoice } from '../../InvoiceContext/InvoiceContext'

export const Service = () => {
  const { t } = useTranslation(['components'])
  const { service } = useInvoice()

  return (
    <div className="content__company">
      <div className="content__company-wrapper">
        <div className="company__text">
          <span className="text__name">{t('components:enot_service.Платёжная форма')}</span>
        </div>
        <a href={service.href} className="company__logo" target="_blank" rel="noopener noreferrer">
          {service.icon ?? service.name}
        </a>
      </div>
    </div>
  )
}
