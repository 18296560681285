import styles from './PerfectMoney.module.scss'
import React, { useEffect, useState } from 'react'

import { useCustomSnackbar } from '../../../../hooks/useCustomSnackbar'
import { PerfectStepsEnum } from './PerfectMoney.types'
import PerfectMoneySelect from './PerfectMoneySelect/PerfectMoneySelect'
import { useMutation } from '@tanstack/react-query'
import { InvoiceService } from '../../../../services/invoice/invoice.service'
import { useMethodsContext } from '../../../../components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { IGetConvertCurrencyPayload } from '../../../../services/invoice/invoice.service.types'
import { PaymentLoader } from '../../../../components/PaymentLoader/PaymentLoader'
import { StatusPayment } from '../../../../components/StatusPayment/StatusPayment'
import { useTranslation } from 'react-i18next'
import { IResponseError } from '../../../../types/IResponse'

export const PerfectMoney = (): JSX.Element => {
  const { t } = useTranslation(['components'])
  const enqueueSnackbar = useCustomSnackbar()
  const { stepOneData } = useMethodsContext()
  const [selectedCurrency, setSelectedCurrency] = useState<'USD' | 'EUR'>('USD')
  const [perfectSteps, setPerfectSteps] = useState<PerfectStepsEnum>(PerfectStepsEnum.SELECT)

  const { mutate: mutate_payPerfect } = useMutation(
    ['payPerfect'],
    (email?: string) =>
      InvoiceService.payPerfect({
        currency: selectedCurrency,
        secretKey: data_convertCurrency?.data?.data?.signature,
        email: email || undefined,
        // email: 'test@mail.ru',
      }),
    {
      onSuccess: ({ data }) => {
        setPerfectSteps(PerfectStepsEnum.FINAL)

        if (data?.data?.url) {
          window.location.href = data?.data?.url
        }
      },
      onError: (err: IResponseError) => {
        if (err.response?.data.error) {
          enqueueSnackbar(err.response?.data.error, {
            variant: 'error',
          })
        }
      },
    }
  )

  const {
    mutate: mutate_convertCurrency,
    data: data_convertCurrency,
    isLoading: isLoading_convertCurrency,
    isError: isError_convertCurrency,
    error: error_convertCurrency,
  } = useMutation(
    ['convertCurrency'],
    (data: IGetConvertCurrencyPayload) => InvoiceService.getConvertCurrency(data),
    { onError: (_error: IResponseError) => null }
  )

  const handleSubmitButton = (email?: string) => {
    mutate_payPerfect(email)
  }

  useEffect(() => {
    if (
      stepOneData?.signature &&
      stepOneData?.service_type === 'perfect_money_form' &&
      perfectSteps === PerfectStepsEnum.SELECT
    ) {
      mutate_convertCurrency({
        secretKey: stepOneData?.signature,
        currencies: ['USD', 'EUR'],
      })
    }
  }, [stepOneData])

  const perfectStepsContent = {
    [PerfectStepsEnum.SELECT]: (
      <PerfectMoneySelect
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
        handleSubmit={handleSubmitButton}
        currencyData={data_convertCurrency?.data?.data?.amounts}
        isLoading={isLoading_convertCurrency}
      />
    ),

    [PerfectStepsEnum.FINAL]: (
      <PaymentLoader text={t('components:payment_loader.Идет переадресация')} />
    ),
  }

  if (isError_convertCurrency) {
    return (
      <StatusPayment
        type="error"
        title={t('components:status_payment.Произошла ошибка')}
        text={
          error_convertCurrency?.response?.data?.error ??
          t('components:status_payment.Произошла ошибка при загрузке курсов')
        }
      />
    )
  }
  return <div className={styles.wrapper}>{perfectStepsContent[perfectSteps]}</div>
}
