export const lunhAlgorithm = (inputString: string): boolean => {
  // remove whitespace
  inputString = inputString.split(' ').join('')
  // invalid if too short
  if (inputString.length < 1) {
    return false
  }
  // if there's an invalid character, it'll catch
  try {
    const numbers = inputString.split('').map((elem) => parseInt(elem))
    const reversedNumbers = numbers.slice().reverse()
    const sum = reversedNumbers.reduce((total, current, index) => {
      if (index % 2 == 1) {
        return total + (current * 2 < 9 ? current * 2 : current * 2 - 9)
      } else {
        return total + current
      }
    }, 0)
    return sum % 10 == 0
  } catch (error) {
    return false
  }
}
