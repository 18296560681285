import { CircularProgress } from '@mui/material'
import React, { ReactNode } from 'react'
import styles from './PaymentLoader.module.scss'
import { useTranslation } from 'react-i18next'

export const PaymentLoader = ({
  text,
  subText,
}: {
  text?: string | ReactNode
  subText?: string | ReactNode
}) => {
  const { t } = useTranslation(['components'])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.loader}>
          <CircularProgress color="inherit" size={48} />
        </div>

        <div className={styles.text}>
          {text || t('components:status_payment.Ищем ваш платеж')}
          <div className={styles.dots}></div>
        </div>

        {subText && <div className={styles.subtext}>{subText}</div>}
      </div>
    </div>
  )
}
