import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SidebarStatusEnum,
  useSidebar,
} from '../../../components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { MethodHeader } from '../../../components/MethodHeader/MethodHeader'
import { StatusPayment } from '../../../components/StatusPayment/StatusPayment'
import { Button } from '../../../ui-lava/Button/Button'

export const PaymentError = ({ error, serviceName }: { error: string; serviceName: string }) => {
  const { t } = useTranslation(['components', 'ui'])
  const { invoiceId } = useParams()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { setSidebarStatus } = useSidebar()

  const clickHandler = () => {
    setSidebarStatus(SidebarStatusEnum.EXPANDED)
    queryClient.invalidateQueries(['getInvoiceInfo'])
    navigate(`/${invoiceId}/${serviceName}`, {
      state: { service: serviceName },
    })
  }

  return (
    <>
      <MethodHeader service={serviceName} />

      <StatusPayment
        type="error"
        text={error}
        title={t('components:payment_error.Произошла ошибка')}
        action={
          <Button onClick={clickHandler}>
            {t('ui:buttons.Попробовать еще раз')}
          </Button>
        }
      />
    </>
  )
}
