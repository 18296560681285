import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { SidebarProvider } from './components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { MethodsContent } from './components/Layout/Invoice/InvoiceContent/MethodsContent/MethodsContent'
import { InvoiceLayout } from './views/Invoice/InvoiceLayout'
import { SelectMethodPlug } from './components/SelectMethodPlug/SelectMethodPlug'
import { InvoiceProvider } from './components/Layout/Invoice/InvoiceContext/InvoiceProvider'
import { StatusPage } from './views/StatusPage/StatusPage'
import { useMediaQuery } from 'react-responsive'
import { PaymentConfirmation } from './components/Layout/PaymentConfirmation/PaymentConfirmation'
import { useTranslation } from 'react-i18next'
import { RedirectCheck } from './components/Layout/RedirectCheck/RedirectCheck'
import { viewportCalc } from './helpers/viewport_calc.helper'
import { WidgetLayout } from './views/Widget/WidgetLayout/WidgetLayout'
import { Widget } from './views/Widget/Widget'
import Checkout from 'components/Layout/Checkout/Checkout'

viewportCalc()

window.addEventListener('resize', viewportCalc)

const ruUseDeskLink = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_161828_42794.js'
const enUseDeskLink = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_161828_43463.js'

function App() {
  const { i18n } = useTranslation()

  //Все выбранные методы зависят от роутинга. У каждого метода - свой роут
  const isTabletS = useMediaQuery({
    query: '(max-width: 576px)',
  })

  return (
    <InvoiceProvider>
      <SidebarProvider>
        <div className="App">
          <Routes>
            <Route path="/:invoiceId/*" element={<InvoiceLayout />}>
              {/* Индексовый элемент - заглушка под выбор метода */}
              <Route index element={!isTabletS && <SelectMethodPlug />} />

              {/* Вся логика методов - тут */}
              <Route path=":methodName" element={<MethodsContent />} />
              {/* <Route path="bitcoin" element={<Bitcoin />} /> */}

              {/* Страница статуса платежа */}
              <Route path="status" element={<StatusPage />} />
            </Route>

            <Route path="/checkout/:checkoutId" element={<Checkout />} />

            {/* по этому роуту потдверждение формы тдс */}
            <Route path="/invoice/*" element={<PaymentConfirmation />} />

            <Route path="/widget/:widgetId" element={<WidgetLayout />}>
              <Route path=":widgetType" element={<Widget />} />
            </Route>

            <Route path="/redirect-check" element={<RedirectCheck />} />
          </Routes>
        </div>
      </SidebarProvider>
    </InvoiceProvider>
  )
}

export default App
