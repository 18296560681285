import React from 'react'
import styles from './IconButton.module.scss'
import cn from 'classnames'
import { IIconButton } from './IconButton.types'

export const IconButton = ({ children, buttonStyle, ...props }: IIconButton) => {
  return (
    <button {...props} className={cn(styles.iconButton, styles[buttonStyle])}>
      {children}
    </button>
  )
}
