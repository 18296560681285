import React from 'react'
import { IStatusPayment } from './StatusPayment.types'
import { PaymentLoader } from '../PaymentLoader/PaymentLoader'
import styles from './StatusPayment.module.scss'
import { IconProgressPayment } from '../IconProgressPayment/IconProgressPayment'

export const StatusPayment = ({ type, title, text, action }: IStatusPayment) => {
  return (
    <div className={styles.container}>
      {type === 'loading' ? (
        <PaymentLoader />
      ) : (
        <>
          <div className={styles.icon}>
            <IconProgressPayment type={type} />
          </div>
          <h3 className={styles.title}>{title ?? ''}</h3>
          <p className={styles.text}>{text ?? ''}</p>

          <div className={styles.button}>{action}</div>
        </>
      )}
    </div>
  )
}
