import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { InvoiceService } from '../../../../../services/invoice/invoice.service'
import { StatusPayment } from '../../../../StatusPayment/StatusPayment'
import { IMethodsContext, MethodsStepEnum } from './MethodsContent.type'
import { AvailableMethodsEnum } from '../../../../../helpers/types/availableMethods.type'
import { MethodHeader } from '../../../../MethodHeader/MethodHeader'
import { PaymentLoader } from '../../../../PaymentLoader/PaymentLoader'
import { MethodsContentSkeleton } from './MethodsContentSkeleton/MethodsContentSkeleton'
import { useTranslation } from 'react-i18next'
import { useScript } from '../../../../../hooks/useScript/useScript'
import { IInvoiceAmount, useInvoice } from '../../InvoiceContext/InvoiceContext'
import { PaymentCardInputValues } from '../../../../../views/Invoice/Methods/Card/CardForm/PaymentCardInput/PaymentCardInput'
import { IResponseError } from '../../../../../types/IResponse'
import {
  IGetStepOne,
  IGetStepOneResponse,
} from '../../../../../services/invoice/invoice.service.types'
import { AxiosResponse } from 'axios'
import { MethodsContentByStep } from '../../../../MethodsContentByStep/MethodsContentByStep'
import { FormStatus } from 'components/FormStatus/FormStatus'
import styles from "./MethodsContent.module.scss";

const MethodsContext = createContext<IMethodsContext>({
  stepOneData: {} as IGetStepOne,
  onSubmitPayment: (_value: PaymentCardInputValues) => null,
  email: '',
  setEmail: () => null,
  methodsStep: undefined,
  setMethodsStep: (_value: MethodsStepEnum | undefined) => null,
})

export const useMethodsContext = () => useContext(MethodsContext)

export const MethodsContent = () => {
  const { t } = useTranslation(['components'])
  const navigate = useNavigate()
  useScript('https://fp-gw.com/static/fp.js')

  const { invoiceStatus, isRedirecting, setIsRedirecting, setInvoiceAmount, invoiceAmount } = useInvoice()

  const { methodName, invoiceId } = useParams<{
    methodName?: AvailableMethodsEnum
    invoiceId: string
  }>()

  const [email, setEmail] = useState<string>('')
  //Список доступных методов. Рендерятся на основе роута
  const [methodsStep, setMethodsStep] = useState<MethodsStepEnum>()

  //Запрос на step-on
  const {
    data: data_getStepOne,
    isLoading: isLoading_getStepOne,
    mutate: mutate_getStepOne,
    isError: isError_getStepOne,
    error: error_getStepOne,
  } = useMutation<AxiosResponse<IGetStepOneResponse>, IResponseError>(
    ['getStepOne'],
    () =>
      InvoiceService.getStepOne({
        invoiceId: invoiceId ?? '',
        method: methodName ?? '',
      }),
    {
      onSuccess: ({ data }) => {
        //Для юмани тут приходит этот статус. Чтоб пользователь не зашел на страницу инвойса и не оплачивал несколько раз
        if (data?.data?.redirect_on_status) {
          return navigate(`/${invoiceId}/status`)
        }
        if (data?.data?.url && data.data.is_redirect) {
          if (!data?.data?.fingerprint || !window.initUserFingerprint) {
            window.location.href = data?.data?.url ?? ''
            return setIsRedirecting(true)
          }
          // Обработка промиса и редирект на оплату
          return window.initUserFingerprint().then(() => {
            window.location.href = data?.data?.url ?? ''
            setIsRedirecting(true)
          })
        }

        if (data?.data?.waiting) {
          return setMethodsStep(MethodsStepEnum.PROCESS)
        }
        //Если есть поле use_email - значит нужно ввести емейл для чека. В случае с картами - вводится внутри компонента
        if (
          data?.data?.use_email &&
          methodName !== AvailableMethodsEnum.CARD &&
          methodName !== AvailableMethodsEnum.PERFECT
        ) {
          setMethodsStep(MethodsStepEnum.EMAIL)
        } else {
          setMethodsStep(MethodsStepEnum.PROCESS)
        }

        if (data?.data?.amount) {
          setInvoiceAmount((prev: IInvoiceAmount) => ({
            ...prev,
            amount: data?.data?.amount,
          }))
        }
      },
    }
  )

  useEffect(() => {
    setMethodsStep(undefined)
  }, [methodName])

  useEffect(() => {
    methodName && mutate_getStepOne()
  }, [methodName])

  if (!methodName) {
    return null
  }

  if (isRedirecting) {
    return <PaymentLoader text={t('components:payment_loader.Идет переадресация')} />
  }

  return (
    <MethodsContext.Provider
      value={{
        stepOneData: data_getStepOne?.data?.data,
        onSubmitPayment: () => null,
        email,
        setEmail,
        setMethodsStep,
        methodsStep,
      }}
    >
      <>
        <MethodHeader service={methodName} />
        {invoiceAmount.currency === "UZS" ?
          <div className={styles.warningFormStatus}>
            <FormStatus outlined={true} type="warning" title="" text={t('components:card_form.При пополнении с UZC')} />
          </div>
          : null}
        {isLoading_getStepOne ? (
          <MethodsContentSkeleton />
        ) : isError_getStepOne ? (
          <StatusPayment
            title={t('components:status_payment.Произошла ошибка')}
            text={
              error_getStepOne?.response?.data?.error ??
              t(
                'components:status_payment.Попробуйте выбрать другой метод, либо обратитесь в поддержку'
              )
            }
          />
        ) : (
          <MethodsContentByStep
            invoiceStatus={invoiceStatus}
            methodName={methodName}
            methodStep={methodsStep}
          />
        )}
      </>
    </MethodsContext.Provider>
  )
}
