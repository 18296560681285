import React from 'react'
import './InvoiceLayout.scss'
import { Header } from '../../components/Layout/Invoice/Header/Header'
import { Footer } from '../../components/Layout/Invoice/Footer/Footer'
import { InvoiceContent } from '../../components/Layout/Invoice/InvoiceContent/InvoiceContent'
import {
  SidebarStatusEnum,
  useSidebar,
} from '../../components/Layout/Invoice/Sidebar/SidebarContext/SidebarContext'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { InvoiceService } from '../../services/invoice/invoice.service'
import { useQuery } from '@tanstack/react-query'
import { Sidebar } from '../../components/Layout/Invoice/Sidebar/Sidebar'
import { Divider, useMediaQuery } from '@mui/material'
import { InvoiceLayoutSkeleton } from './InvoiceLayoutSkeleton/InvoiceLayoutSkeleton'
import { StatusPayment } from '../../components/StatusPayment/StatusPayment'
import { AnimatePresence } from 'framer-motion'
import { Helmet } from 'react-helmet'
import {
  IGetInvoiceInfoResponse,
  InvoiceStatusEnum,
} from '../../services/invoice/invoice.service.types'
import { Service } from '../../components/Layout/Invoice/InvoiceContent/Service/Service'
import { useTranslation } from 'react-i18next'
import {
  IInvoiceAmount,
  useInvoice,
} from '../../components/Layout/Invoice/InvoiceContext/InvoiceContext'
import { IResponseError } from '../../types/IResponse'
import { AxiosResponse } from 'axios'
import { SidebarItem } from '../../components/Layout/Invoice/Sidebar/SidebarItem/SidebarItem'

export const InvoiceLayout = () => {
  const navigate = useNavigate()
  const isStatusPage = useMatch('/:id/status')
  const { t } = useTranslation(['components'])
  const { invoiceId, methodName } = useParams()
  const { sidebarStatus, setSidebarStatus, visible } = useSidebar()
  const { search } = useLocation()

  const {
    service,
    invoiceStatus,
    isWaitingForPayment,
    setServicesCount,
    setInvoiceAmount,
    setIsRateButtonVisible,
  } = useInvoice()

  const isTabletS = useMediaQuery('(max-width: 576px)')
  const isLaptopL = useMediaQuery('(max-width: 1024px)')

  const {
    data: data_getInvoiceInfo,
    isLoading: isLoading_getInvoiceInfo,
    isRefetching: isRefetcing_getInvoiceInfo,
    isError: isError_getInvoiceInfo,
    error: error_getInvoiceInfo,
  } = useQuery<AxiosResponse<IGetInvoiceInfoResponse>, IResponseError>(
    ['getInvoiceInfo'],
    () => InvoiceService.getInvoiceInfo(invoiceId ?? ''),
    {
      onSuccess: ({ data }) => {
        const firstService = data?.data?.tariff_group?.[0]?.tariffs?.[0]

        //Количество доступных сервисов
        const countService = data?.data?.tariff_group?.reduce((totalLength, item) => {
          let tariffsLength = 0
          if (item?.tariffs?.length) tariffsLength = item.tariffs.length
          return totalLength + tariffsLength
        }, 0)

        setServicesCount(countService)

        setInvoiceAmount((prev: IInvoiceAmount) => ({
          ...prev,
          amount: Number(data?.data?.amount),
          currency: data?.data?.currency,
        }))

        if (isStatusPage && Number(countService) < 2) {
          return setSidebarStatus(SidebarStatusEnum.CLOSED)
        }

        if (countService === 1) {
          setSidebarStatus(SidebarStatusEnum.CLOSED)
        }

        if (data?.data?.status !== InvoiceStatusEnum.CREATED && !isStatusPage) {
          return navigate('status')
        }
        const currentSearchParams = new URLSearchParams(search)

        //Если 1 сервис - сразу редиректим на него (записываем этот сервис в роутеровский стейт)
        if (countService === 1) {
          navigate(
            `${
              data?.data?.tariff_group?.[0]?.tariffs?.[0].service
            }?${currentSearchParams.toString()}`,
            {
              replace: true,
              state: { service: data?.data?.tariff_group?.[0]?.tariffs?.[0].service },
            }
          )
        } else if (firstService && !firstService.is_final && !isTabletS) {
          //Проверяем, на есть ли в первой группе тарифов
          navigate(
            `${
              data?.data?.tariff_group?.[0]?.tariffs?.[0].service
            }?${currentSearchParams.toString()}`,
            {
              replace: true,
              state: { service: data?.data?.tariff_group?.[0]?.tariffs?.[0].service },
            }
          )

          if (!isLaptopL) {
            setSidebarStatus(SidebarStatusEnum.EXPANDED)
          } else {
            setSidebarStatus(SidebarStatusEnum.COLLAPSED)
          }
        } else {
          setSidebarStatus(SidebarStatusEnum.EXPANDED)
          navigate(``)
        }

        if (!data?.data?.rating) {
          setIsRateButtonVisible(true)
        }
      },
      staleTime: 5000,
    }
  )

  useQuery(['getInvoiceStatus'], () => InvoiceService.getInvoiceStatus(invoiceId ?? ''), {
    onSuccess: ({ data }) => {
      if (data?.data?.status !== InvoiceStatusEnum.CREATED && !isStatusPage) {
        navigate('status')
      }
    },
    enabled: isWaitingForPayment,
    refetchInterval: 5000,
    staleTime: 2000,
  })

  const isVisibleSidebar =
    sidebarStatus !== SidebarStatusEnum.CLOSED &&
    ((isTabletS && !methodName) || !isTabletS) &&
    !isStatusPage

  const firstService = data_getInvoiceInfo?.data?.data?.tariff_group?.[0]?.tariffs?.[0]
  const descriptionContent = `${t('components:description.Оплата заказа')}${service.metaName}`

  return (
    <div className="invoice-wrapper">
      <Helmet>
        <link rel="icon" href={service.favIcon} />
        <title>
          {t('components:title.Оплата заказа')} {service.metaName}
        </title>
        <meta name="description" content={descriptionContent}></meta>
      </Helmet>
      <div className="invoice__header">
        <Header
          data={data_getInvoiceInfo?.data?.data}
          isErrorInvoice={isError_getInvoiceInfo}
          isLoadingInvoice={isLoading_getInvoiceInfo}
        />
      </div>
      <div className="invoice-body">
        {isLoading_getInvoiceInfo || isRefetcing_getInvoiceInfo ? (
          <InvoiceLayoutSkeleton />
        ) : isError_getInvoiceInfo ? (
          <div className="invoice__error-page">
            <StatusPayment
              type="error"
              title={t('components:status_payment.Произошла ошибка')}
              text={
                error_getInvoiceInfo?.response?.data?.error ??
                t('components:status_payment.Попробуйте снова, либо обратитесь в поддержку')
              }
            />
          </div>
        ) : (
          <>
            {isTabletS && !methodName && !isStatusPage && (
              <div>
                <h3 className="items__title">{t('components:sidebar.Способ оплаты')}</h3>
                {isWaitingForPayment && firstService && <SidebarItem item={firstService} />}
              </div>
            )}
            <div className="invoice__content">
              <AnimatePresence exitBeforeEnter>
                {isVisibleSidebar ? (
                  <>
                    <Sidebar data={data_getInvoiceInfo?.data?.data} />
                    <Divider orientation="vertical" />
                    {isTabletS || visible ? (
                      <div>
                        <div className="shadow-top" />
                        <div className="shadow-bottom" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : null}
              </AnimatePresence>

              {invoiceStatus?.visible ? (
                <StatusPayment
                  text={invoiceStatus?.options?.text}
                  title={invoiceStatus?.options?.title}
                  action={invoiceStatus?.options?.action}
                  type={invoiceStatus?.options?.type}
                />
              ) : (
                <InvoiceContent />
              )}
            </div>
          </>
        )}
        {isTabletS && <Service />}
      </div>
      <Footer />
    </div>
  )
}
