import React, { ChangeEvent } from 'react'
import styles from './EmailPayment.module.scss'
import { Button } from '../../../../../../ui-lava/Button/Button'
import { useMethodsContext } from '../MethodsContent'
import { Controller, useForm } from 'react-hook-form'
import { InputLabel } from 'ui-lava/Input/InputLabel/InputLabel'
import { PaymentOffer } from '../../../../../PaymentOffer/PaymentOffer'
import { AvailableServicesType } from '../../../../../../helpers/types/availableMethods.type'
import { useTranslation } from 'react-i18next'
import { deleteSpaces } from '../../../../../../helpers/inputDeleteSpaces'
import { Patterns } from '../../../../../../enums/patterns.const'
import { MethodsStepEnum } from '../MethodsContent.type'

export const EmailPayment = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  })

  const { t } = useTranslation(['ui', 'components'])

  const { email, setEmail, stepOneData, setMethodsStep } = useMethodsContext()

  const handleSubmitForm = ({ email }: { email: string }) => {
    setEmail(email)
    setMethodsStep(MethodsStepEnum.PROCESS)
  }

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        {t('components:email_payment.После оплаты не закрывайте и не обновляйте эту страницу')}
      </p>
      <form
        className={styles.inputWrapper}
        onSubmit={handleSubmit(handleSubmitForm)}
        id="emailInput"
      >
        <Controller
          control={control}
          name={'email'}
          defaultValue={email}
          rules={{
            required: false,
            pattern: {
              value: Patterns.EMAIL,
              message: t('components:card_form.Введите корректный email адрес'),
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <InputLabel
                id="filled-basic"
                label={t('components:email_payment.Введите email')}
                variant="filled"
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  deleteSpaces(e)
                  onChange(e)
                }}
                customError={errors?.email?.message || ''}
                autoFocus={true}
                required={stepOneData?.is_required_email}
              />
            )
          }}
        />
      </form>

      <Button type="submit" form="emailInput" className={styles.button}>
        {t('ui:buttons.Продолжить')}
      </Button>

      {/* TODO */}
      {stepOneData?.service_type === AvailableServicesType.QIWI_URL_FORM && <PaymentOffer />}
    </div>
  )
}
